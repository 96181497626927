export default {
  APP_NAME: '社媒矩陣管理',
  LOGIN: '登錄',
  LOGIN_NAME: '登錄名',
  PASSWORD: '密碼',
  USERNAME_INPUT_TIP: '請輸入用戶名',
  PASSWORD_INPUT_TIP: '請輸入密碼',
  VERIFICATION_CODE_INPUT_TIP: '請輸入驗證碼',
  ERROR_USER_PWD_TIP: '用戶名或密碼不正確！',

  EXIT: '退出',
  MULTILINGUAL: '語言',
  CHANGE_PASSWORD: '修改密碼',
  CONFIRM_PASSWORD: '確認密碼',
  NEW_PASSWORD: '新密碼',
  OLD_PASSWORD: '原密碼',
  PWD_RULE_TIP: '密碼至少8位字符，且必須包含數字、英文字母或特殊字符，至少匹配兩種規則',
  CONFRIM_PWD_NOT_SAME_TIP: '密碼輸入不一致！',
  PAGINATION_INFO_TIP: '共 {total} 條記錄，當前為 {start} - {end} 條',
  ACTION_SUBMIT: '提交',
  ACTION_CONFIRM: '確認',
  ACTION_DELETE: '刪除',
  ACTION_REMOVE: '移除',
  ACTION_SAVE: '保存',
  ACTION_SAVA_DRAFT: '存為草稿',
  ACTION_SUBMIT_PUBLISH: '提交發布',
  ACTION_DOWNLOAD: '下載',
  ACTION_DISABLE: '禁用',
  ACTION_ENABLE: '啟用',
  ACTION_DOWNLOAD_DATA: '下載數據',
  ACTION_VIEW: '查看',
  ACTION_EDIT: '編輯',
  ACTION_OPERATE: '操作',
  ACTION_SEARCH: '搜索',
  ACTION_NEW: '新建',
  ACTION_CANCEL: '取消',
  ACTION_ADD: '添加',
  LOGIN_PWD: '登錄密碼',
  COPY: '復制',
  ACTION_RENAME: '重命名',
  ACTION_ADD_NAME: '添加{name}',
  STATUS: '狀態',

  OPERATION_SUCCESS: '操作成功',
  DELETE_SUCCESS: '刪除成功',
  CREATE_SUCCESS: '創建成功',
  CHANGE_PASSWORD_SUCCESS: '密碼修改成功',
  EDIT_SUCCESS: '編輯成功',
  OPERATION_FAILURE: '操作失敗',
  DELETE_FAILURE: '刪除失敗',
  VALIDATION_EXCEIPTION: '驗證異常',
  NO_PREMISSION: '您暫無權限操作此頁面！',
  NO_DATA: '暫無數據',
  CREATE_TIME: '創建時間',
  CREATOR: '創建者',

  CONFIRM_DELETE_NAME_TIP: '確認是否刪除{name}?',

  //============组件========
  NUM_MONTH: '{num}個月',
  NUM_WEEK: '{num}周',
  NUM_DAY: '{num}天',
  NUM_HOUR: '{num}小時',
  NUM_MINUTE: '{num}分',
  NUM_SECOND: '{num}秒',
  JUST_NOW: '剛剛',
  DATE_BEFORE: '前',
  DATE_AFTER: '後',
  ORGANIZATIONAL_ACCOUNT: '組織賬號',
  PLAY_VIDEO: '播放視頻',
  CLICK_TO_PC_WATCH_VIDEO: '點擊至PC版查看視頻',
  CLICK_TO_WATCH: '點擊觀看',
  ACCOUNT: '賬號',
  APPLY: '應用',
  SELECTED_NUM_COLUMNS: '已選{num}列',
  RESET_TO_DEFAULT: '恢復默認',
  CLEAR_EMPTY: '清空',
  ABOVE_LIST_CANNOT_BE_MODIFIED: '以上列不可修改',
  ERROR_LOADING_COMPONENT_REFRESH_PAGE: '加載組件時出錯，請刷新頁面重試',
  PLEASE_SELECT_AFFILIATED_ORGANIZATION: '請選擇所屬組織',
  CUMULATIVE: '累計',
  AUTHORIZATION_SUCCESSFUL: '授權成功',
  YESTERDAY: '昨天',
  PLAY_HISTORY_LIVE_VIDEO: '播放歷史直播視頻',
  AUTO_LOCATE_CITY: '自動定位城市',
  MANUALLY_FILL_IN_REGION: '手動填寫地域',
  SOURCE_PAGE: '來源頁',
  COMPONENT_TYPE: '組件類型',
  SERVICE_CLUE_SOURCE: '服務號線索來源',
  CALL_HISTORY: '通話記錄',
  LEAD_PROVINCE_ORIGIN: '線索用戶歸屬地省份',
  LEAD_CITY_ORIGIN: '線索用戶歸屬地城市',
  ADVERTISING_GROUP_ID: '廣告組ID',
  LANDING_PAGE_ID: '落地頁ID',
  ADVERTINSING_CREATIVAITY_ID: '廣告創意ID',
  ADVERTINSING_CREATIVAITY: '廣告創意',
  ADVERTINSING_GROUP_NAME: '廣告組名稱',
  LANDINGPAGE_NAME: '落地頁名稱',
  PURPOSE_INFO: '意向信息',
  INTENDED_GOODS: '意向商品',
  INREND_PROVINCE_NAME: '線索用戶意向省份',
  INREND_CITY_NAME: '線索用戶意向城市',
  STORE_PROVINCE: '門店信息-省份',
  STORE_CITY: '門店信息-城市',
  STORE_NUMBER: '門店信息-門店編碼',
  STORE_INFO_NAME: '門店信息-門店名稱',
  FORM_ID: '表單ID',
  FORM_NAME: '表單名稱',
  PAY_ORDER_NO: '支付表單訂單編號',
  PAY_ORDER_STATE_DES: '支付表單訂單狀態描述',
  COUPON_ID: '卡券組件-卡券ID',
  COUPON_NAME: '卡券組件-卡券名稱',
  COUPON_NUMBER: '卡券組件-券碼',
  COUPPON_PHONE: '卡券組件-手機號',
  COUPON_EXPIRE: '卡券組件-到期時間',

  NUMBER_OF_LEADS: '留資次數',
  ADVERTISEMENT_INFORMATION: '廣告信息',
  ADVERTISER_ID: '廣告主ID',
  ADVERTISER_NAME: '廣告主名稱',
  AD_CAMPAIGN_ID: '廣告計劃ID',
  AD_CAMPAIGN_NAME: '廣告計劃名稱',
  CREATIVE_ID: '創意ID',
  ADVERTISEMENT_ID: '廣告ID',
  ADVERTISEMENT_NAME: '廣告名稱',
  COMPANY_INFORMATION: '企業信息',
  OTHER_INFORMATION: '其他信息',
  LEAD_ID: '線索ID',
  IS_OFFSITE_CLUE: '是否異地線索',
  LOCAL_LOCATION: '本地',
  OTHER_AREA: '異地',
  UNCERTAIN: '無法判斷',
  CAR_INFORMATION: '汽車信息',
  INTENDED_CAR_SERIES: '意向車系',
  INTENDED_CAR_MODEL: '意向車型',
  PURCHASE_INTENTION: '購車意向',
  OTHER: '其他',
  OPERATOR_MOBILE_NUMBER: '運營者手機號',
  REGION_AREA: '大區',
  REGION_NAME: '大區名稱',
  DEALER: '經銷商',
  DEALER_NAME: '經銷商名稱',
  EMPLOYEE: '員工',
  EMPLOYEE_NAME: '員工名稱',
  STORE: '門店',
  STORE_INFO: '門店信息',
  STORE_NAME: '門店名稱',
  STORE_CODE: '門店編碼',
  INFLUENCER: '達人',
  INFLUENCER_NAME: '達人名稱',
  DEALER_CODE: '經銷商代碼',
  TIKTOK_ACCOUNT_INFORMATION: '抖音賬號信息',
  ACCOUNT_INFORMATION: '賬號信息',
  TIKTOK_NICKNAME: '抖音暱稱',
  PLATFORM_TYPE: '平台類型',
  TOTAL_VIDEOS: '視頻總數',
  NEW_FANS: '新增粉絲',
  NET_FOLLOW_FANS: '淨增粉絲',
  NEW_LEADS: '新增線索',
  HOMEPAGE_VISITORS: '主頁訪問人數',
  SHORT_VIDEO: '短視頻',
  NEW_VIDEOS: '新增視頻',
  NEW_PLAY: '新增播放',
  NEW_LIKES: '新增點贊',
  NEW_COMMENTS: '新增評論',
  NEW_SHARES: '新增分享',
  DURATION_MINUTES: '時長（分鐘）',
  AUDIENCE_NUMBER: '觀眾數',
  AVERAGE_STAY_DURATION_MINUTES: '平均停留時長(分鐘)',
  AVERAGE_STAY_DURATION_SECONDES: '平均停留時長(秒)',
  AVERAGE_STAY_DURATION: '平均停留時長',
  LEAD_GENERATION: '留資',
  LIVE_SCENE_LEAD_NUMBER: '直播全場景線索人數',
  LIVE_ROOM_FORM_SUBMITTED_LEAD_COUNT: '直播間表單提交線索次數',
  ORANGE_SITE_LEAD_COUNT: '橙子建站線索次數',
  ORGANIC_TRAFFIC_LEAD_COUNT: '自然流量線索次數',
  TOTAL_LEADS_COUNT: '留資總數（次）',
  TOTAL_LEADS: '留資總數',
  VIDEO_LEADS: '視頻留資（次）',
  LIVE_LEADS: '直播留資（次）',
  MESSAGE_LEADS: '私信留資（次）',
  HOMEPAGE_LEADS: '主頁留資（次）',
  ENTER_MESSAGE_SESSION_NUMBER: '進入私信會話人數',
  MESSAGE_SESSION_INITIATOR_NUMBER: '私信會話開口人數',
  MESSAGE_LEAD_QUANTITY: '私信線索量（人數）',
  MESSAGE_LEAD_CONVERSION_RATE: '私信線索轉化率',
  ACCEPTED_MESSAGES: '接受消息數',
  MISSED_MESSAGES: '漏回消息數',
  THREE_MIN_REPLY_RATE: '3min回復率',
  ADVERTISEMENT: '廣告',
  AD_DIRECT_LEAD_QUANTITY: '廣告-直接線索量',
  AD_INDIRECT_LEAD_QUANTITY: '廣告-間接線索量',
  AD_SPEND: '廣告消耗',
  AD_LEAD_COST: '廣告線索成本',
  DIRECT_LEAD_COST: '直接線索成本',
  AD_IMPRESSIONS: '廣告展示次數',
  AD_CLICK_THROUGH_RATE: '廣告點擊率',
  NATIVE_AD_MANAGEMENT_NEW_FANS: '原生廣告經營-新增粉絲量',
  NATIVE_AD_MANAGEMENT_SHORT_VIDEO_SHARES: '原生廣告經營-短視頻分享量',
  NATIVE_AD_MANAGEMENT_SHORT_VIDEO_COMMENTS: '原生廣告經營-短視頻評論量',
  NATIVE_AD_MANAGEMENT_SHORT_VIDEO_LIKES: '原生廣告經營-短視頻點贊量',
  NATIVE_AD_MANAGEMENT_HOMEPAGE_VISITS: '原生廣告經營-主頁訪問人數',
  NATIVE_AD_MANAGEMENT_ENTER_MESSAGE_PEOPLE: '原生廣告經營-進入私信人次',
  LP_SCREEN_LIVE_WATCH_UV_TOTAL: '直播觀眾數',
  LIVE_STREAM_THEME: '直播主題',
  EXPOSURE_UV: '氣泡曝光人數',
  EXPOSURE_CLICK_UV: '氣泡點擊人數',
  COLLECTED_LEAD_NUM: '已收集線索數',
  EXPOSURE_CONVISION_RATE: '曝光點擊轉化率',
  LIVE_DIRATION: '直播時長(分鐘)',
  XHS_ACCOUNT_NICKNAME: '小紅書暱稱',
  NEW_IMAGE_TEXT_FANS_COUNT: '圖文新增粉絲',
  INCREASED_FANS_NUM_FROM_VIDEO: '視頻新增粉絲',
  INCREASED_FANS_NUM_FROM_NOTE: '筆記新增粉絲',
  IMAGE_TEXT_TOTAL: '圖文總量',
  NOTE_TOTAL: '筆記總量',
  XHS_ACCOUNT: '小紅書號',
  ADD_PICTURE_TEXT: '新增圖文',

  ADD_PLAY_FROM_PICTURE_TEXT: '圖文新增觀看',
  ADD_COMMENT_FROM_PICTURE_TEXT: '圖文新增評論',
  ADD_LIKE_FROM_PICTURE_TEXT: '圖文新增點贊',
  ADD_SHARE_FROM_PICTURE_TEXT: '圖文新增分享',
  ADD_FAV_FROM_PICTURE_TEXT: '圖文新增收藏',
  ADD_RECOMMENDS: '新增推薦',

  TURN_ON_NAVIGATION: '開啟導航',
  TURN_OFF_NAVIGATION: '關閉導航',
  HOME_PAGE: '首頁',
  COPY_SUCCESSFUL: '復制成功',

  //=============平台=========
  PLATFORM: '平台',
  PLATFORM_DOUYIN: '抖音',
  PLATFORM_KUAISHOU: '快手',
  PLATFORM_XIHONGSHU: '小紅書',
  PLATFORM_SHIPINGHAO: '視頻號',

  //=============菜单==============
  WORKBENCH: '工作台',
  USER_CENTER: '用戶中心',
  DATA_VIEW: '數據概覽',
  DATA_STATISIICS: '數據統計',
  PLATFORM_ACCOUNT: '平台賬號',
  LIVE_MANAGE: '直播管理',
  LIVE_LIST: '直播列表',
  VIDEO_OPERATIONS: '矩陣運營',
  WORK_MANAGEMENT: '作品管理',
  WORK_PUBLISH: '作品發布',
  INTERACTIVE_MANAGEMENT: '互動管理',
  CONVERSATION_MANAGEMENT: '會話管理',
  SESSION_AGGREGAITION: '會話聚合',
  HISTORICL_CONVERSATION: '歷史會話',
  COMMENT_MANAGEMENT: '評論管理',
  LIVE_BARRAGE_MANAGEMENT: '直播彈幕管理',
  CUSTOMER_ACQUISITION_MANAGEMENT: '獲客管理',
  CLUE_MANAGEMENT: '線索管理',
  HIEGH_POTENTIAL_USERS: '高潛用戶',
  SYSTEM_CONFIGATION: '系統配置',
  AUTHORIZED_ACCOUNT: '授權賬號',
  SEMANTIC_CLASSIFICATION: '語義分類',
  ORGANIZATIONAL_MANAGEMENT: '組織管理',
  PERMISSION_MANAGE: '權限管理',
  PERMISSION_USER: '用戶管理',
  PERMISSION_USERGROUP: '用戶組管理',
  PERMISSION_ROLE: '角色管理',
  TAG: '標簽',
  TAG_MANAGEMENT: '標簽管理',

  //=============表单提示==============
  PLEASE_SELECT: '請選擇',
  PLEASE_SELECT_MULTIPLE_NAME: '-請選擇{name}（多選）-',
  PLEASE_SELECT_SINGLE_NAME: '-請選擇{name}-',
  PLEASE_TYPE: '請輸入',
  PLEASE_TYPE_NAME: '請輸入{name}',
  PLEASE_TYPE_NUMBER: '請輸入數字',
  BASIC_INFO: '基本信息',
  CREATE_NAME: '創建{name}',
  EDIT_NAME: '編輯{name}',
  VIEW_NAME: '查看{name}',

  //=============账户==============
  FANS: '粉絲',
  VIDEO: '視頻',
  LIVE: '直播',
  LIVT_BROADCAST_TOTAL: '直播總場次',
  TOTAL_NEW_FANS: '新增粉絲數',
  TOTAL_LOST_FANS: '流失粉絲數',
  OVERVIEW: '總覽',
  TREND_ANALYSIS: '趨勢分析',
  FAN_PORTRAIT: '粉絲畫像',
  USER_ANALYSIS: '用戶分析',
  GENDER_DISTRIBUTION: '性別分布',
  AGE_DISTRIBUTION: '年齡分布',
  VIEW_REPORT: '查看報告',
  COMMENT_TIME: '評論時間',
  NICKNAME: '用戶暱稱',
  AFFILIATION_ORG: '所屬組織',
  ACCOUNT_NAME: '賬號名稱',
  AUTHORIZE_ACCOUNT_NAME: '授權賬號名稱',
  ACCOUNT_REPORT_AUDIENCE_ACTIVE_TIME: '受眾活躍時間',
  ACCOUNT_REPORT_PROVINCE_DISTRIBUTION: '常駐省份排行Top10',
  ACCOUNT_REPORT_CROWD_DISTRIBUTION: '八大人群分布情況',
  CROWD_DISTRIBUTION: '人群分布情況',
  REGION_REPORT_DISTRIBUTION_TOP: '地區分布Top10',
  INTEREST_REPORT_DISTRIBUTION_TOP: '興趣分布Top10',
  INTEREST_DISTRIBUTION: '興趣分布',
  ACCESS_DEVICES: '訪問設備',
  ACTIVE_TIME: '活躍時間',
  SYSTEM_DISTRIBUTION: '系統分布',
  DATA_TRANDS: '數據趨勢',
  FUNS_SOURCE: '粉絲來源',
  PROVINCE_DISTRIBUTION: '省級分布',
  CITY_DISTRIBUTION: '城市分布',
  LIVE_ROOM_ID: '直播間ID',
  LIVE_ROOM_ID_TIP: '請輸入數字,多個用英文逗號分隔',
  LIVE_TIME: '開播時間',
  RANKINGS: '排名',
  PROVINCE: '省',
  PEOPLE_COUNT: '人數',
  TIME_COUNT: '次數',
  PERCENTAGE: '佔比',
  NOTE: '筆記',

  //=============交互管理==============
  INTERACTIVE_COMMENT_DATA_TIPS: '每個直播間只展示非自見或者不涉及官方規則的彈幕',
  BULLET_CONTENT: '彈幕內容',
  KEYWORD_CLASSIFICATION: '關鍵字分類',
  KEYWORD_HIT: '命中關鍵詞',
  PUBLISH_ACCOUNT: '發布賬號',
  ORGANIZATION_TYPE: '組織類型',
  ACTION_LIVE_REPLAY: '直播回放',
  ACTION_VIEW_COMMENT: '查看評論',
  ACTION_MORE_BARRAGE: '更多彈幕',
  NO_COMMENT: '暫無評論',
  COMMENT_COUNT_TIP: '共 {count} 個評論',
  DATA_UPDATE_TIME_TIP: '當前數據更新時間為: {syncTime}',
  AFFILIATION_PLATFORM: '所屬平台',
  SEND_TIME: '發送時間',
  ALL: '全部',
  UNCLASSIFIED: '未分類',
  COMMENT_CONTENT: '評論內容',
  WORK: '作品',
  WORK_ACCOUNT: '作品賬號',
  CONVERSATION_CARD_TIP: '[卡片消息] 暫不支持該消息類型 請到app端查看',
  CONVERSATION_NOT_SUPPORT_TIP: '暫不支持該消息類型 請到app端查看',
  CONVERSATION_INFO: '會話信息',
  CONVERSATION_CURRENT: '本次會話',
  CONVERSATION_ALL: '全部會話',
  CONVERSATION_SEARCH_TIP: '搜索會話內容',
  TIKTOK_ACCOUNT: '抖音號',
  MATRIX_ACCOUNT_NAME: '矩陣賬號名',
  ADDRESS: '地址',
  USER: '用戶',
  SESSION_RECORDS: '會話記錄',
  MESSAGE_COUNT: '消息總數',
  SESSION_TIME: '對話時間',
  SESSION_END_TIME: '會話結束時間',
  SESSION_DURATION: '對話時長',
  SESSION_ACCOUNT: '對話賬號',
  NO_CONVERSATIONS: '暫無會話',
  TOTAL_CONVERSATIONS_COUNT: '共 {count} 個會話',
  TOTAL_COUNT: '共 {total} 條',
  NO_NOME_INFOMATION: '無更多信息',
  CUSTOMER_READY_TALK_NO: '顧客發起會話，會話編號',
  SELLER_FINISH_TALK_NO: '客服結束會話，會話編號',
  SHARE_VIDEO: '分享視頻',
  SEND_EMOJI: '發送表情',

  //=============获客管理==============
  SOURCE_TIKTOK_ACCOUNT: '來源抖音號',
  SOURCE_TIKTOK_NICKNAME: '來源抖音暱稱',
  SHIPINGHAO_NICKNAME: '視頻號暱稱',
  SHIPINGHAO_ACCOUNT: '視頻號',
  CLUE: '線索',
  CLUE_TYPE: '線索類型',
  CLULE_ACCOUNT: '線索賬號',
  INTERACTION_TYPE: '互動類型',
  CLUE_CHANNEL: '線索渠道',
  FLOW_TYPE: '流量類型',
  NAME: '姓名',
  CLUE_CRATE_TIME: '線索創建時間',
  LAST_ACTION_TIME: '最近互動時間',
  CUSTOM_COLUMNS: '自定義列',
  LIVE_BROADCAST_ROOM_BARRAGE: '直播間彈幕',
  VIDEO_COMMENT: '視頻評論',
  VIEW_DETAIL: '查看詳情',
  RERA: '所屬地域',
  CLUE_DETAIL_COMMENT_TPL: '{nickName} 通過 {accountName} 在{mediaName}-《{title}》留下評論:「{content}」',
  CLUE_DETAIL_BULLET_TPL: '{nickName} 通過 {accountName} 在{mediaName} 直播中發送彈幕:「{content}」',
  CLUE_DETAIL_SESSION_TPL: '{nickName} 通過 {accountName} 發起{mediaName}私信對話: 「{content}」',
  CLUE_DETAIL_CLUE_TPL: '{nickName} 通過 {accountName} ,在 {actionType} 留資：「{content}」',
  PRIVATE_LETTER: '私信',
  VIEW_CLUE_DETAIL: '查看線索詳情',
  TELEPHONE: '電話',
  TRAFFIC_SOURCE: '流量來源',
  INTERACTION_RECORDS: '互動記錄',
  INTERACTIVE_TIME: '互動時間',
  INTERACTION_ACCOUNT: '互動賬號',
  CLUE_BASIC_INFO: '線索基礎信息',
  USER_TIKTOK_ACCOUNT: '用戶抖音號',
  USER_TIKTOK_NICKNAME: '用戶抖音暱稱',
  USER_NICKNAME: '用戶賬號暱稱',
  USER_ACCOUNT: '用戶賬號',
  CUSTOMER_CITY: '客戶城市',
  JOB_CANDIDATES: '應聘崗位',
  CITIES: '城市',
  CALL_STATUS: '通話狀態',
  USER_INFO: '用戶信息',

  AGE: '年齡',
  GENDER: '性別',
  WECHAT: '微信',
  BIRTHDAY: '生日',
  CLUE_SOURCE_INFO: '線索來源信息',
  IS_FUNDS: '是否留資',
  FUNDS_YES: '已留資',
  FUNDS_NO: '未留資',
  CITY_AREA: '地區',
  CLUE_INFO: '線索信息',
  LATEST_CLUE: '最新線索',
  COMMENT_COUNT: '意向評論次數',
  NUM: '序號',
  KS_ACCOUNT: '快手號',
  KS_NICKNAME: '快手暱稱',
  COLLECTION_TIME: '收集時間',
  FILTER_RESULT: '清洗結果',
  CLUE_PREGRESS: '線索進度',
  TRANSFORM: '轉化',

  //============系统组织结构==========
  CALL_NAME: '名稱',
  CODE: '編碼',
  ORG_TOTAL_TIP: ' 共{count}個{title}',
  DELETE_ORG_CONFIRM_TIP: '確認刪除組織結構「{name}」?',
  DELETE_ORG_CONFIRM_SUB_TIP: '刪除後不可找回',

  ORG_NAME_EXISTED: '組織機構名稱已存在',
  INPUT_TEXT_VALIDATATION_TIP: '最多{count}個字符，支持英文、數字及符號',
  FULL_ADDRESS: '詳細地址',

  //================= 矩阵运营 =================
  PICTURE_NOTE: '圖文',
  PICTURE: '圖片',
  LIST_WORKS: '作品列表',
  COVER: '封面',
  TITLE_OF_WORK: '作品標題',
  PUBLISH_TIME: '發布時間',
  ACTION_COMMENT: '評論',
  ACTION_VIEW_INTERACTIVE: '查看互動數據',
  TOTAL_NUMBER_OF_WORKS: '作品總量',
  STATIST_UNIT_TIAO: '條',
  OPERATIONAL_DATA: '運營數據',
  INTERACTIVE_EFFECT_OF_WORKS: '作品互動效果',
  WORK_INDICATORS: '作品指標',
  DRAFT: '草稿',
  PUBLISHED: '已發布',
  REMOVED: '已刪除',
  REPLY: '回復',
  CLOSE_REPLY: '關閉回復',
  COMMENT_PLACEHOLDER_TIP: '有愛評論，說點兒好聽的～',
  EMOTICON: '表情',
  SEND: '發送',
  SELECT_EMOTICON: '選擇表情',
  SUMMARY_OF_COMMENTS: '評論匯總',
  COMMENT_TIME_ID: '評論時間/評論ID',
  CLASSIFICATION: '分類',
  HIDE_ALL_REPLIES: '收起全部回復',
  EXPAND_NUM_REPLIES: '展開{num}條回復',
  NO_MORE_DATA: '沒有更多啦',
  AFTER_THE_VIDEO_IS_RELEASED: '視頻發布後',
  PLAY_COUNT_TODAY: '當日播放量',
  SHARE_COUNT_TODAY: '當日分享',
  COMMENT_COUNT_TODAY: '當日評論',
  LIKE_COUNT_TODAY: '當日喜歡',
  FAV_COUNT_TODAY: '當日推薦',
  FOLLOW_COUNT_TODAY: '當日關注',

  PLAY_TODAY: '當日播放數',
  LIKE_TODAY: '當日點贊數',
  SHARE_TODAY: '當日分享數',
  COMMENT_TODAY: '當日評論數',
  DATE: '日期',
  LAST_NUM_DAYS: '近{num}天',
  LIKE_COUNT_TOTAL: '累計點贊數',
  SHARE_COUNT_TOTAL: '累計分享數',
  COMMENT_COUNT_TOTAL: '累計評論數',
  WORKS_COUNT_TOTAL: '累計作品總量',
  MATRIX_OPERATION_STATISTICS_TIP:
    '1.統計數據在賬號首次授權後實時更新；2.趨勢及明細數據需在用戶首次授權應用後的第二天方可展示，且僅可提供三十天內創建視頻的數據。',
  WORK_DETAILS: '作品詳情',
  COMPLETION_RATE: '完播率',
  INDICATOR_DATA: '指標數據',
  LIKE_NEGATIVE_NUM_TIP: '點贊數為負數時，可能是由於用戶取消點贊。',
  AVG_PLAY_DURATION: '平均播放時長',
  LIKE: '喜歡',
  RECOMMEND: '推薦',
  FELLOW: '關注',
  SHARE: '分享',
  PLAY: '播放',
  LIKE_COUNT_TOAL: '累計喜歡',
  RECOMMEND_COUNT_TOTAL: '累計推薦',
  COMMENT_COUNT_NUM_TOTAL: '累計評論',
  FOLLOW_COUNT_TOTAL: '累計關注',
  SHARE_COUNT_NUM_TOTAL: '累計分享',
  FAVORITE_DAILY_NUM: '當日收藏數',
  VIEWS_COUNT_TOTAL: '累計觀看量',
  CUMULATIVE_WATCH_PV: '累計觀看人次',
  NOTE_COUNT_TOTAL: '累計筆記總量',
  VIDEO_COUNT_TOTAL: '累計視頻總量',
  IMAGE_NOTE_COUNT_TOTAL: '累計圖文總量',
  LIKED_TOTAL: '累計點贊量',
  COLLECTION_TOTAL: '累計收藏數',
  BARAGE_TOTAL: '累計彈幕數',
  INCREASING_NOTES_TOTAL: '累計筆記漲粉數',
  INCREASING_FUNS_TOTAL: '累計直接漲粉數',
  NOTE_TYPE: '筆記類型',
  VIEW_NUM: '觀看量',
  COLLECTIONS_NUM: '收藏數',
  BULLET_COMMENTS_NUM: '彈幕數',
  INCREASING_FOLLOWERS: '直接漲粉數',
  ITEM_CLUE_COUNT: '視頻留資',
  LIVE_CLUE_COUNT: '直播留資',
  OTHER_CLUE_COUNT: '其他留資',
  CAPTION_REQUIRED_TIP1: '作品簡介，最多支持1000個文字輸入',
  CAPTION_REQUIRED_TIP2: '作品標題和簡介，最多支持1000個文字輸入',

  //===========作品发布======
  STATUS_UNPUBLISHED: '待發布',
  STATUS_PUBLISHING: '發布中',
  STATUS_PUBLISHE_SUCCESS: '發布成功',
  STATUS_PUBLISHE_FAILURE: '發布失敗',
  APPLICATION_INFO: '應用信息',
  LINK_URL: '鏈接地址',
  PLEASE_SELECT_APPLICATION: '請選擇應用',
  MINI_APP: '小程序',
  SELECT_MINI_APP: '選擇小程序',
  PLEASE_COPY_DY_APP_LINK: '請復制粘貼抖音小程序鏈接',
  PERMISSION_SETTING: '權限設置',
  ALLOW_SAVING_VIDEO: '允許保存視頻',
  WORK_INTRODUCTION: '作品簡介',
  WORK_INTRODUCTION_TYPE_INPUT_TIP: '添加作品簡介，最多{num}個文字輸入',
  VIDEO_MOUNTING: '視頻掛載',
  PLATFORM_NOT_CONFIGED_TIP: '平台信息未配置!',
  UNMONTING: '不掛載',
  VIDEO_MOUNTING_TIP: '若設置視頻掛載，不同賬號發布的相同內容掛載信息將為同一個。',
  SEND_ACCOUNT_NUM_TIP: '共計選擇發送{num}個賬號',
  POSITON_SETTING_TIP: '若設置當前地點，不同賬號發布的相同內容地址信息將為同一個',
  VIDEO_TITLE: '視頻標題',
  VIDEO_TITLE_TIP: '短標題會出現在搜索、話題、活動、地點、訂閱號消息、發現頁紅點等場景。',
  VIDEO_TITLE_PLACEHOLDER: '概括主要內容，建議6-16字符',
  ORIGINAL: '原創',
  VIDEO_ORIGINAL_FIELD_TIP: '個人號不支持原創',
  VIDEO_ORIGINAL_FIELD_TEXT_DESCRIBE: '視頻時長小於5秒以及圖文內容，不能使用原創聲明。',
  UPLOAD_VIDEO_FILE_TIP: '上傳視頻僅支持單個視頻，格式為mp4、mov視頻文件!',
  UPLOAD_VIDEO_DURATION: '需上傳播放時長不能超過15分鐘的視頻',
  UPLOAD_VIDEO_MAX_SIZE_TIP: '上傳視頻文件大小不能超過128MB',
  CLICK_TO_UPLOAD: '點擊上傳',
  FILE_UPLOAD_REQUIRD_TIP:
    '支持mp4 、webm格式 上傳視頻文件大小超過128MB，且視頻時長不能超過15分鐘 推薦上傳 16:9，分辨率為 720p (1280x720) 及以上的版視頻。',
  FILE_UPLOAD_EXCEEDED: '超過最大上傳數量',
  PUBLISH_SINGLE_IMG_REQUIRED: '單張圖片最大支持 20M',
  PUBISH_IMG_WIDTH_HEIGHT_REQUIRED: '圖文的寬高比建議不超過1:2',
  PUBISH_IMG_REQUIRED:
    '支持常用圖片格式，暫不支持gif格式  單張圖片最大支持 20M，每次最多上傳 10 張圖片  圖文的寬高比建議不超過1:2',
  RESOUTION_TRTIO_REQUIRED_TIP: '分辨率過低，請上傳分辨率大於324*432的圖片',
  VERTICAL_SCREEN: '豎屏',
  LANDSCAPE_SCREEN: '橫屏',
  COVER_IMG_SCREEN_REQUIRED: '支持jpeg、png、jpg格式 , 最大支持10M  小紅書圖文發布與視頻號圖文發布暫不支持封面上傳',
  TASK_NAME: '任務名稱',
  CONTENT_SETTING: '內容設置',
  PUBLISH_TIME_REQUIRED_TIP: '發布時間不能早於當前時間',
  CONTENT_TYPE: '內容類型',
  PUBLISH_TYPE: '發布類型',
  PUBLISH_VIDEO: '發布視頻',
  PLEASE_UPLOAD_VIDEO: '請上傳發布視頻',
  UPLOAD_PICTURE: '上傳圖片',
  UPLOAD_COVER: '上傳封面',
  RE_UPLOAD: '重新上傳',
  EDIT_IMG: '編輯圖片',
  UPLOAD_VIDEO_TIP:
    '帶品牌 logo 或品牌水印的視頻，會命中抖音的審核邏輯，有比較大的概率導致分享視頻推薦降權處理/分享視頻下架處理/分享賬號被封禁處理。建議第三方應用自行處理好分享內容中的不合規水印。',
  UPLOAD_PIC_TEXT: '發布圖文',
  PLEASE_UPLOAD_PIC_TEXT: '請上傳發布圖文',
  PUBLISH_COVER_IMG: '發布封面',
  PLATFORM_CONFIG: '平台配置',
  PUBLISH_TYPE_INSTANT_SEND: '立即發送',
  PUBLISH_TYPE_SCHEDULE_SEND: '定時發送',

  PUBISH_STATUS_DRAFT_TEXT: '草稿',
  PUBLISH_STATUS_PENDING_TEXT: '待執行',
  PUBLISH_STATUS_PROCESSING_TEXT: '執行中',
  PUBLISH_STATUS_PAUSED_TEXT: '已暫停',
  PUBLISH_STATUS_FINISHED_TEXT: '已完成',

  PUBLISH_TASK_STATUS_PREPARATION_TEXT: '待發布',
  PUBLISH_TASK_STATUS_PUBLISHING_TEXT: '發布中',
  PUBLISH_TASK_STATUS_SUCCESS_TEXT: '發布成功',
  PUBLISH_TASK_STATUS_FAILURE_TEXT: '發布失敗',
  PUBLISH_TASK_STATUS_PAUSED_TEXT: '已暫停',

  PERMISSION_TYPE_PUBLISH_TEXT: '公開',
  PERMISSION_TYPE_PRIVATE_TEXT: '私密',
  PERMISSION_TYPE_FRIENDLY_TEXT: '好友可見',

  ALLOW: '允許',
  NOT_ALLOW: '不允許',
  NEW_PUBLISH_ITEM: '新建作品發布',
  VIEW_PUBLISH_ITEM: '查看作品發布',
  EDIT_PUBLISH_ITEM: '編輯作品發布',

  PUBLISH_RECORDS: '發布記錄',
  TASK_STATUS: '任務狀態',
  SUCCESS_COUNT: '發布成功數',
  FAILURE_COUNT: '發布失敗數',
  ACTION_STOP_TASK: '暫停任務',
  ACTION_RESTART_TASK: '重啟任務',
  STOP_TASK_CONFIRM_TIP: '是否暫停發布任務?',
  STOP_TASK_CONTENT_TIP: '確認後系統將暫停內容發布。',
  RESTART_TASK_CONFIRM_TIP: '是否重啟任務?',
  RESTART_TASK_CONTENT_TIP: '確認後系統將重啟任務發布。',
  CONFIRM_DELETE_TASK_CONFIRM_TIP: '是否刪除發布任務？',
  CONFIRM_DELETE_TASK_CONTENT_TIP: '確認刪除後將無法找回。',
  ERROR_LINK_FORMAT_TIP: '鏈接格式錯誤或參數不正確',
  CONFIRM_REPUBLISH_TIP: '是否重新發布?',
  CONFIRM_REPUBLISH_CONTENT_TIP: '確認後系統將立即執行內容發布',
  ACTION_BATCH_PUBLISH: '批量發布',
  PUBLISH_STATUS: '發布狀態',
  PUBLISH_FINISH_TIME: '發布完成時間',
  PUBOISH_TITLE: '發布視頻標題',
  UN_OPENED: '未開啟',
  GENERAL_CONFIG: '通用配置',
  UPLOAD_VIDEO: '上傳視頻',

  //=============直播==============
  VIDEO_INFO: '視頻信息',
  LP_SCREEN_GUIDE_ITEM_COUNT: '直播入口曝光次數',
  LP_SCREEN_GUIDE_ITEM_WATCH_COUNT: '引流直播間次數',
  LP_SCREEN_GUIDE_ITEM_ENTER_RATIO: '直播入口點擊率',
  LIVE_STREAMING_TRAFFIC: '直播引流',
  LIVE_STREAMING_RECOMANDATION: '直播推薦',
  HEAT_FLOW: '加熱流量',
  AD_FLOW: '廣告流量',
  MALE: '男',
  FEMALE: '女',
  LIVE_DATA: '直播數據',
  LIVE_ACCOUNT: '直播賬號',
  LIVE_END_TIME: '關播時間',
  LIVE_DURATION: '直播時長',
  ACTION_REPORT: '報告',
  ACTION_REPLAY: '回放',
  STATIST_UNIT_GE: '個',
  STATIST_UNIT_CI: '次',
  STATIST_UNIT_CHANG: '場',
  STATIST_UNIT_YUAN: '元',
  STATIST_UNIT_REN: '人',
  STATIST_UNIT_REN_CI: '人次',
  STATIST_UNIT_SEC: '秒',
  STATIST_UNIT_MIN: '分鐘',
  STATIST_UNIT_HOUR: '小時',
  STATIST_UNIT_DAY: '天',
  STATIST_UNIT_WEEK: '周',
  STATIST_UNIT_MONTH: '個月',
  STATIST_UNIT_YEAR: '年',
  LIVIE_CHANNEL_TRAFFIC_ANALYSIS: '渠道流量分析',
  LIVE_STATIST_ACCOUNT_NUM: '開播賬號數',
  LIVE_STATIST_ACCOUNT_NUM_TIP: '匯總篩選條件下有開播的賬號數量',
  LIVE_STATIST_LIVE_NUM: '直播場次',
  LIVE_STATIST_LIVE_TOTAL_NUM: '累計直播場次',
  LIVE_STATIST_LIVE_NUM_TIP: '匯總篩選條件下賬號發起直播場次累計數',
  LIVE_STATIST_LIVE_DURATION: '累計直播時長',
  LIVE_STATIST_LIVE_DURATION_TIP: '匯總篩選條件下賬號發起直播的累計時長',
  LIVE_STATIST_VIEW_NUM: '累計觀看人數',
  LIVE_STATIST_VIEW_NUM_TIP: '匯總篩選條件下賬號發起直播的累計觀看人數，不同場次之間的人數不去重',
  LIVE_STATIST_DURATION_AVG_TIP: '篩選條件下賬號發起直播，直播間觀看時長（累計）/進入直播間用戶數（累計）',
  LIVE_STATIST_COMMENT_NUM: '累計評論次數',
  LIVE_STATIST_COMMENT_NUM_TIP: '匯總篩選條件下賬號發起直播的累計評論次數',
  LIVE_STATIST_CLUE_NUM: '累計線索量',
  LIVE_STATIST_CLUE_NUM_TIP: '匯總篩選條件下賬號發起直播的累計線索次數',
  INTERACTIVE_EFFECT: '互動效果',
  DATA_INDICATORS: '數據指標',
  LIVE_INDICATOR_WATCH_UV: '觀看人數',
  LIVE_INDICATOR_UV: '累計觀看人數',
  LIVE_INDICATOR_UV_TIP: '整場直播的場觀人數',
  LIVE_INDICATOR_MAX_WATCH_UV: '最高在線人數',
  LIVE_INDICATOR_MAX_WATCH_UV_TIP: '整場直播的最高在線人數',
  LIVE_INDICATOR_AVG_WATCH_DURATION_TIP: '所有人觀看時長之和 / 場觀',
  LIVE_INDICATOR_FOLLOW_UV: '漲粉量',
  LIVE_INDICATOR_FOLLOW_UV_TIP: '直播期間獲得的粉絲數，點擊左上角關注人數',
  LIVE_INDICATOR_COMMENT_UV: '評論人數',
  LIVE_INDICATOR_COMMENT_UV_TIP: '有評論動作的人數，去重統計，一人多次評論算一次',
  LIVE_INDICATOR_MSG_CONVERSATION_COUNT: '私信人數',
  LIVE_INDICATOR_MSG_CONVERSATION_COUNT_TIP:
    '本場直播期間產生私信會話的人數(包含用戶沒有開口，商家設置了主動問候語的情況)',
  LIVE_INDICATOR_CLUE_UV: '全場景線索人數',
  LIVE_INDICATOR_CLUE_UV_TIP: '單場直播時間內 各渠道(小風車卡片提交， 私信留資，智能電話留資)產生的線索',
  LIVE_INDICATOR_WATCH_GT_MIN_COUNT: '>1分鐘觀看人次',
  LIVE_INDICATOR_WATCH_GT_MIN_COUNT_TIP: '單次觀看時長大於1分鐘的人次 (非人數)',
  LIVE_INDICATOR_USER_REALTIME: '實時在線人數',
  LIVE_INDICATOR_USER_REALTIME_TIP: '直播期間每分鐘實時人數。下播後該值為0',
  LIVE_INDICATOR_FANS_WATCH_UV: '粉絲數',
  LIVE_INDICATOR_BUSINESS_CARD_CLICK_COUNT: '卡片點擊次數',
  LIVE_INDICATOR_BUSINESS_CARD_CLICK_COUNT_TIP:
    '僅統計了卡片(抖音APP直播間右下角講解卡 和 小風車裡的卡片)的點擊, 並且是次數(非人數)',
  LIVE_INDICATOR_SHOW_COUNT: '曝光次數',
  LIVE_INDICATOR_SHOW_COUNT_TIP: '直播間曝光所觸達的次數，不去重統計',
  LIVE_INDICATOR_ENTER_RATIO: '曝光進入率',
  LIVE_INDICATOR_ENTER_RATIO_TIP: '累計觀看人數 / 曝光人數',
  LIVE_INDICATOR_ICON_CLICK_COUNT: '小風車點擊次數',
  LIVE_INDICATOR_ICON_CLICK_COUNT_TIP: '僅統計了小風車的點擊(不含講解卡), 並且是次數(非人數)',
  LIVE_INDICATOR_ICON_CLICK_RATE: '小風車點擊率',
  LIVE_INDICATOR_ICON_CLICK_RATE_TIP: '小風車點擊率 = 小風車點擊次數 / 觀看次數',
  LIVE_INDICATOR_INTERACTION_RATIO: '互動率',
  LIVE_INDICATOR_INTERACTION_RATIO_TIP: '互動人數 / 累計觀看人數',
  LIVE_INDICATOR_BIZ_WECHAT_COST: '加微信成本',
  LIVE_INDICATOR_BIZ_WECHAT_COST_TIP: '加微信成本 = 廣告消耗 / 企業微信添加數',
  LIVE_INDICATOR_FORM_COUNT: '表單提交數',
  LIVE_INDICATOR_FORM_COUNT_TIP: '巨量廣告平台直播大屏同名指標，僅限於掛載小雪花或者外鏈的情況時使用。',
  LIVE_INDICATOR_WATCH_COUNT: '觀看次數',
  LIVE_INDICATOR_WATCH_COUNT_TIP: '整場直播的觀看次數，不去重統計',
  LIVE_INDICATOR_FANS_WATCH_RATIO: '粉絲佔比',
  LIVE_INDICATOR_FANS_WATCH_RATIO_TIP: '觀看為粉絲的人數 / 累計觀看人數',
  LIVE_INDICATOR_AVG_ONLINE_UV_BY_ROOM: '平均在線人數',
  LIVE_INDICATOR_AVG_ONLINE_UV_BY_ROOM_TIP: '每分鐘最高在線人數相加 / 直播分鐘數',
  LIVE_INDICATOR_UV_WITH_PREVIEW: '看過',
  LIVE_INDICATOR_UV_WITH_PREVIEW_TIP:
    '對應抖音APP下播頁的那個看過人數，等於進入直播間的人加上推薦流預覽觀看直播間超過3秒但沒有進入直播間的人',
  LIVE_INDICATOR_SHARE_COUNT: '分享次數',
  LIVE_INDICATOR_SHARE_COUNT_TIP: '有分享行為的次數，不去重統計',
  LIVE_INDICATOR_SHARE_UV: '分享人數',
  LIVE_INDICATOR_SHARE_UV_TIP: '有分享行為的人數，去重統計，一人多次分享算一次',
  LIVE_INDICATOR_SHARE_RATIO: '分享率',
  LIVE_INDICATOR_SHARE_RATIO_TIP: '分享人數 / 累計觀看人數',
  LIVE_INDICATOR_LIKE_UV: '點贊人數',
  LIVE_INDICATOR_LIKE_UV_TIP: '有點贊行為的人數，去重統計，一人多次點贊算一次',
  LIVE_INDICATOR_LIKE_COUNT: '點贊次數',
  LIVE_INDICATOR_LIKE_COUNT_TIP: '有點贊行為的次數，不去重統計',
  LIVE_INDICATOR_LIKE_RATIO: '點贊率',
  LIVE_INDICATOR_LIKE_RATIO_TIP: '點贊人數 / 累計觀看人數',
  LIVE_INDICATOR_COMMENT_COUNT: '評論次數',
  LIVE_INDICATOR_COMMENT_COUNT_TIP: '有評論動作的次數，不去重統計',
  LIVE_INDICATOR_COMMENT_RATIO: '評論率',
  LIVE_INDICATOR_COMMENT_RATIO_TIP: '評論人數 / 累計觀看人數',
  LIVE_INDICATOR_INTERACTION_COUNT: '互動次數',
  LIVE_INDICATOR_INTERACTION_COUNT_TIP: '有 點贊，評論，分享 任意動作的次數，不去重統計',
  LIVE_INDICATOR_INTERACTION_UV_COUNT: '互動人數',
  LIVE_INDICATOR_INTERACTION_UV_COUNT_TIP:
    '有 點贊，評論，分享 任意動作的人數，去重統計，一人多次點贊，評論，分享 算一次',
  LIVE_INDICATOR_FANS_CLUB_JOIN_UV: '加粉絲團人數',
  LIVE_INDICATOR_FANS_CLUB_JOIN_UV_TIP: '加粉絲團人數，去重統計',
  LIVE_INDICATOR_FANS_CLUB_JOIN_UV_RATIO: '加團率',
  LIVE_INDICATOR_FANS_CLUB_JOIN_UV_RATIO_TIP: '加粉絲團人數 / 累計觀看人數',
  LIVE_INDICATOR_FOLLOW_RATIO: '關注率',
  LIVE_INDICATOR_FOLLOW_RATIO_TIP: '漲粉量/累計觀看人數',
  LIVE_INDICATOR_CLUE_CONVERT_RATIO: '線索轉化率',
  LIVE_INDICATOR_CLUE_CONVERT_RATIO_TIP: '全場景線索人數/累計觀看人數',
  LIVE_INDICATOR_FORM_COST: '表單成本',
  LIVE_INDICATOR_FORM_COST_TIP: '表單成本 = 廣告消耗 / 表單提交數',
  LIVE_INDICATOR_LONGTERM_MSG_CLUE_UV: '私信長效轉化',
  LIVE_INDICATOR_LONGTERM_MSG_CLUE_UV_TIP:
    '直播期間發生了私信會話但沒有留資，下播後1小時內留資人數。2023年05月10號後才有數據',
  LIVE_INDICATOR_BUSINESS_CARD_CLICK_RATE: '卡片點擊率',
  LIVE_INDICATOR_BUSINESS_CARD_CLICK_RATE_TIP: '卡片點擊率 = 卡片擊次數 / 卡片曝光次數',
  LIVE_INDICATOR_BIZ_WECHAT_ADD_COUNT: '企業微信添加數',
  LIVE_INDICATOR_BIZ_WECHAT_ADD_COUNT_TIP: '巨量廣告平台直播大屏同名指標，僅限於掛載小雪花或者外鏈的情況時使用',
  LIVE_INDICATOR_CLUE_COST: '線索成本',
  LIVE_INDICATOR_CLUE_COST_TIP: '直播間廣告消耗金額/直播間線索總量',
  LIVE_INDICATOR_STAT_COST: '廣告消耗',
  LIVE_INDICATOR_STAT_COST_TIP: '與該直播間關聯的投廣消耗',
  LIVE_COMMENT_NUMBER: '共 {count} 個彈幕評論',
  LIVE_INDICATOR_TIP: '趨勢分析至少展示1個維度，最多展示3個維度的數據',
  LIVE_STATIST_AUDIENCE_TRAFFIC_SOURCE: '觀眾流量來源',
  LIVE_STATIST_AGE: '{age}歲',
  LIVE_STATIST_AGE_ABOUT: '{age}歲以上',
  LIVE_STATIST_AUDIENCE_DATA: '觀眾數據',
  LIVE_STATIST_INTERACTIVE_DATA: '互動數據',
  LIVE_STATIST_CONVERT_DATA: '轉化數據',
  SATREAMING_SHORT_VIDEO: '引流短視頻',
  TRAFFIC_CONVERSION: '流量轉化',
  USER_PROFILE_ANALYSIS: '用戶畫像分析',
  RESIDENCE_DISTRIBUTION: '停留分布',
  TRAFFIC_ANALYSIS: '流量分析',
  CITY_TOP_10: '城市排名Top10',
  PROVINCE_TOP_10: '省份排名Top10',
  LIVE_DETAIL_PLAY_TIP: '每個直播間只展示非自見或者不涉及官方規則的彈幕',
  LIVE_DETAIL_TAB_COMMENT: '評論',
  LIVE_DETAIL_TAB_HIGH_LIGHT: '高光時刻',
  LIVE_INDICATOR_GROUP_POPULARITY: '人氣指標',
  LIVE_INDICATOR_GROUP_INTERACTIVE: '互動指標',
  LIVE_INDICATOR_GROUP_CONVERT: '轉化指標',
  LIVE_INDICATOR_GROUP_OTHER: '其他指標',
  REAL_TIME_DYNAMICS: '實時動態',
  TIME_MINUTE_TPL: '{time}分鐘',
  TIME_HOUR_TPL: '{time}小時',
  LIVE_CLICK_PLAY_HIGH_LIGHT: '點擊播放高光時刻',
  LIVE_DETAIL: '直播詳情',
  SUMMARY_OF_BARRAGES: '彈幕匯總',
  LIVE_VIEW_USER_COUNT: '累積觀看人數',
  CLUE_ANALYSIS: '線索分析',
  CLUE_TRAFFIC_SOURCCE: '線索流量來源',
  AREA_DISTRIBUTION: '地域分布',
  CITY: '市',
  SEGMENTED_SCENE_ANALYSIS: '細分場景分析',
  LIVE_ACCUMULATED_VIEWER_TOTAL: '累計觀眾數',
  PEAK_ONLINE_USER: '在線人數峰值',
  CLUE_COLLECTION_NUM: '線索收集量',
  GIFT_RECEIVED_NUM: '收禮數',
  GIFT_COLLECTION_FLOW_NUM: '收禮流水',
  CLUE_VOMUME_MOUNTING_RATE: '線索量掛載率',
  ADD_FOLLOW: '新增關注',
  TOTAL_HEAT: '總熱度',
  FAN_DISTRIBUTION: '粉絲分布',
  CHANNEL_DISTRIBUTION: '渠道分布',
  REGION_AREA_TOP_5: '地域分布Top5',
  SHARE_LIVE_ROOM_USER_COUNT: '分享直播間人數',
  SHARE_LIVE_ROOM_TIME_COUNT: '分享直播間次數',
  GIFT_GIVE_USER_COUNT: '送禮人數',
  GIFT_GIVE_HEAT: '送禮熱度',
  ENTER_ROOM_USER_COUNT: '進房人數',
  LEAVE_ROOM_USER_COUNT: '離開人數',
  DATA_OVERVIEW: '數據總覽',
  TRAFFIC_AND_SOURCE_TREND: '流量&來源趨勢',
  TRAFFIC_TREND: '流量趨勢',
  INTERACTIVE_TREND: '互動趨勢',
  CUMULATIVE_WATCH_COUNT: '累計觀看人數統計',
  ADD_TRACKING_STATISTICS: '新增關注統計',
  LIVE_COVER_TITLE: '直播標題',
  WX_ACCOUNT: '公眾號',
  APPLET: '小程序',
  BROADCAST_NOTICE: '開播通知',

  //================工作台============
  PLAY_ANALYSIS: '播放分析',
  CONVERT_ANALYSIS: '轉化分析',
  LIVE_PLAY_TOP10: '直播排名Top10',
  VIDEO_PLAY_TOP10: '視頻排名Top10',
  FANS_SORT_TOP10: '粉絲排名Top10',
  LEADS_RORT_TOP10: '線索量排名Top10',
  PLAY_COUNT: '播放次數',
  TIME: '時間',
  ASC: '升序',
  DESC: '降序',
  BY_NAME: '按{name}',
  CONTENT_ANALYSIS: '內容分析',
  LIVE_OVERVIEW: '直播概覽',
  LIVE_TREND_ANAYLISIS: '直播趨勢分析',
  LIVE_TREND: '直播趨勢',
  FANS_ANALYSIS: '粉絲分析',
  RANK_STATISTICS: '排名統計',
  VIDEO_OVERVIEW: '視頻概覽',
  VIDEO_TREND_ANAYLISIS: '視頻趨勢分析',
  TRANSFORMATION_ANALYSIS: '轉化分析',
  VIDEO_CONVERSION: '視頻轉化',
  LIVE_CONVERSION: '直播轉化',
  PEOPLE_NUM: '{num}人',
  PEOPLE: '人',
  NUM_CLOCK: '{num}點',
  NUM_AGE: '{num}歲',
  OVER_NUM_AGE: '{num}+歲',
  FANS_COUNT: '粉絲總數',
  UNKNOWN: '未知',

  LP_SCREEN_CLUE_UV: '全場景線索人數',
  LP_SCREEN_LIVE_CARD_CLICK_UV: '線索工具點擊人數',
  LP_SCREEN_LIVE_SHOW_UV: '直播間曝光人數',
  LP_SCREEN_LIVE_WATCH_UV: '直播進入人數',

  LIVE_TOTAL: '直播場次',
  LP_SCREEN_LIVE_COMMENT_COUNT: '評論次數',
  LP_SCREEN_LIVE_LIKE_COUNT: '點贊次數',
  LP_SCREEN_LIVE_SHARE_COUNT: '分享次數',
  LP_SCREEN_LIVE_VIEW_UV: '觀看人數',

  INTERACTION_TOTAL: '互動總次數',
  ITEM_CLUE_TOTAL: '視頻留資次數',
  PLATY_TOTAL: '累計播放量',

  COMMENT_TOTAL: '評論數',
  VIDEO_ITEM_TOTAL: '視頻發布',
  LIKE_TOTAL: '點贊數',
  PLAY_TOTAL: '播放量',
  SHARE_TOTAL: '分享數',
  OPERATION_ACCOUNT_TOTAL: '運營賬號總數',
  EXCEPTION_ACCOUNT: '異常賬號',
  FANS_TOTAL: '粉絲總數',
  CLUE_TOTAL: '線索總量',
  LIVT_TOTAL_TIME: '直播總時長',
  TIMES_EVERY_WEEK: '次/周',
  LIVE_ACCUMULATED_WATCH_TOTAL: '直播累計觀看人數',
  AVG_WATCH_TIME: '人均觀看時長',
  AVG_WATCH_TIME_SECOND: '人均觀看時長(秒)',

  LIVE_FREQUENCY: '直播頻次',
  VIDEO_PUBLISH_TOTAL: '視頻發布總量',
  PALY_TOTAL: '累積播放量',
  PUBLISH_FREQUENCY: '發布頻次',
  LIVE_WATCH_COUNT: '觀看次數',
  LIVE_LIKE_COUNT: '點贊次數',
  LIVE_COMMENT_COUNT: '評論次數',
  LIVE_COMMENT_UV: '評論人數',
  LIVE_SHARE_COUNT: '分享次數',
  THEATER_COMMAND: '戰區',
  STATIST_UNIT_JIA: '家',
  VIDEO_TOTAL: '視頻總量',
  STAY_ANALYSIS: '停留分析',
  INTERACTIVE_ANALYSIS: '互動分析',
  LIKE_CLICK: '點贊',
  RISING_FOLLOWERS_ANALYSIS: '漲粉分析',
  NEW_FUNS_COUNT: '新增粉絲量',
  RETENTION_ANALYSIS: '留資分析',
  RETNETION_AMOUNT: '留資量',
  WORK_SUMMARY_ANALYSIS: '作品分析',
  LIVE_SUMMARY_ANALYSIS: '直播分析',

  //=============系统授权===============
  ACCOUNT_STATUS_INIT: '初始化中',
  ACCOUNT_STATUS_EXPIRED: '二維碼已過期',
  ACCOUNT_STATUS_CONFIRMED: '已掃碼確認',
  ACCOUNT_STATUS_REFUSED: '授權登錄已經拒絕',
  ACCOUNT_STATUS_NEW: '等待掃碼中',
  ACCOUNT_STATUS_ERROR: '發生錯誤',
  ACCOUNT_STATUS_SUCCESS: '授權成功',
  ACCOUNT_STATUS_FAILURE: '授權失敗',
  ACCOUNT_STATUS_EXCEPTION: '授權異常',
  ACCOUNT_STATUS_SCANNED: '已掃碼',
  AUTHORIZATION_SUCCESS: '授權成功',
  DOUYIN_NO_COPY_ITP: '在抖音APP中，進入「我」的頁面點擊抖音即可復制',
  SCAN_QR_ERROR: '掃碼異常',
  AUTHORIZATION_STATUS: '授權狀態',
  LGOIN_AUTH_STATUS: '登錄狀態',
  GHOST_AUTH_STATUS: '附身狀態',
  GHOST_AUTH_STARTTIME: '附身授權開始時間',
  GHOST_AUTH_ENDTIME: '附身授權結束時間',
  ACCOUNT_BIND_STATUS: '綁定狀態',
  ACCOUNT_OCEAN_BIND_STATUS: '巨量引擎授權',
  CREATOR_AUTH_STATUS: '創作者授權狀態',
  CREATOR_AUTH_STARTTIME: '創作者授權開始時間',
  CREATOR_AUTH_ENDTIME: '創作者授權結束時間',
  ACCOUNT_TYPE: '賬號類型',
  DOWNLOAD_AUTH_LINK: '下載授權鏈接',
  DOWNLOAD_AUTH_LINK_TIP: '抖音平台授權有效期為195天，登錄授權有效期為60天，到期後需要重新授權',
  AUTHORIZATION_PLATFORM: '授權平台',
  PLATFORM_AUTHORIZATION: '平台授權',
  DELER_CODE: '經銷商編碼',
  SYNC_BI_DATA: 'BI報表更新',
  SYNC_CLUE_RULES: '線索規則更新',
  ACCOUNT_AUTHORIZATION: '賬號授權',
  AUTHORIZATION_EXPIRED: '授權已過期',
  ADD_ACCOUNT: '添加賬號',
  UN_AUTHORED_ACCOUNT: '未授權賬號 {num}',
  ANONMOUS_ACCOUNT_AUTHORIZATION_TIP: '當前正在運行 「{businessUnionName}」  賬號授權',
  NEW_AUTHORIZATION_ACCOUNT: '新建授權賬號',
  ACCOUNT_AUTHORIZED_TIP:
    '授權後，視頻數據將拉取近30天內的視頻相關數據；會話將拉取近一年內的相關數據；直播數據將拉取近60天的直播數據。',
  UNAUTHORIZATION_TIP: '請先進行平台授權',
  UNLOGIIN_AUTHORIZATION: '請先進行登錄授權',
  AUTHORIZATION_ORG_UNSELECTED_TIP: '若未選擇所屬組織默認為品牌抖音號',
  PHONENUMBER_PLACEHOLDER: '請輸入正確的手機號碼',
  POI_POSITION: '地理位置',
  REMARK: '備注',
  OPERATOR_PHONE_NUM: '運營者手機',
  OPEN_QR_CODE_POP_UP_TIP: '點擊打開二維碼彈窗進行',
  AUTHORIZATION: '授權',
  REAUTHORIZATION: '重新授權',
  LOGIN_AUTHORIZATION: '登錄授權',
  CREATOR_AUTHORIZATION: '創作者授權',
  PLEASE_AGREE_AUTHORIZE: '請先同意授權',
  PLEASE_PLATFORM_AUTHORIZE: '請先進行平台授權',
  CONFIRM_REAUTHORIZATION: '確認重新授權？',
  AFTER_REAUTHORIZATION_TIP: '重新授權後將會清空原授權狀態。',
  AUTHORIZATION_TIME: '授權時間',
  CHECK_INDICATE_AGREEMENT: '勾選即代表同意',
  APPLICAITON_LICENSE_AGREEMENT: '應用許可協議',
  ACCOUNT_AUTHORIZATION_CANCELED_TIP: '該賬號的授權已被管理者在抖音後台取消，請點擊重新授權',
  ACCOUNT_AUTHIRIZATION_EXPIRED_TIP: '該賬號的授權已過期，請點擊重新授權',
  CLICK_HERE: '點擊此處可',
  GOTO_PLATFORM_AUTHORIZATION_PAGE_TIP: '點擊跳轉至抖音賬號授權頁進行',
  OBTAINED_FOLLOWING_PERMISSION: '已獲得以下權限',
  WILL_OBTAINE_FOLLOWING_PERMISSION: '將獲得以下權限',
  QUICK_ANS_SECURE_LOGIN: '快捷安全登錄',
  RETRACT_UNOBTAINED_FOLLOWING_PERMISSION: '收起未獲得權限',
  EXPEND_UNOBTAINED_FOLLOWING_PERMISSION: '展開未獲得權限',
  REQUIED_ITEM_CHECKED_TIP: '請檢查必填項及相關項目是否正確輸入！',
  PHONE_NUMBER: '手機號碼',
  DELER_CODE_REQUIRED_TIP: '請輸入經銷商編碼',
  ENTERPRISE_ACCOUNT: '企業號',
  PERSONAL_ACCOUNT: '個人號',
  CLOSE: '關閉',
  TRY_REFRESH_QRCODE_TIP: '刷新二維碼異常,請重試',
  CLICK_TO_REAUTHORIZE: '點擊重新發起授權',
  CLICK_LOAD_QRCODE: '點擊加載二維碼',
  CLICK_TO_RELOAD: '點擊重新加載',
  COPY_AUTHORIZATION_INFO: '復制授權信息',
  COPY_TEXT_TO_CLIPBOARD: '文本已復制到剪貼板！',
  COPY_TEXT_FAILURE: '復制文本失敗',
  AUTH_LINK: '授權鏈接',
  LOAD_MROE: '加載更多',
  SELECT_POSITION: '請選擇位置',
  CLEAR: '清除',
  SELECT_AFTER_INPUT_KEYWORD: '請輸入關鍵詞後再選擇',
  PLEASE_SET_POSIITON: '請設置位置',
  POSITION: '位置',
  REGION: '區域',
  PLATFROM_QR_AUTH_TIP1: '獲得你的手機號碼',
  PLATFROM_QR_AUTH_TIP2: '獲取你的{platfrom}頭像、暱稱',
  PLATFROM_QR_AUTH_TIP3: '往你的{platfrom}賬號內發布公開作品（授權後無需你操作發布）',
  PLATFROM_QR_AUTH_TIP4: '獲取你發布的{platfrom}視頻數據（播放數、點贊數、評論數、分享數）',
  AUTHORIZATION_INFO: '授權信息',
  ORG_CODE: '組織編碼',
  VIEW_AUTHORIZED_ACCOUNT: '查看授權賬號',
  LOGIN_AUTH_START_TIME: '登錄授權開始時間',
  LOGIN_AUTH_END_TIME: '登錄授權結束時間',
  PLATFORM_AUTH_END_TIME: '平台授權開始時間',
  OCEAN_ENGINE_AUTH: '巨量授權',
  OPEN_APP_AND_SCAN_QR: '使用 {platform}APP ，打開 [首頁 - 更多 - 掃一掃]',

  //============系统语义分类========
  KEYWORD_CHANGED_TIP: '每次新增、修改或重啟語義分類會在第二天0點正式生效',
  NO_KEYWORD_CLASSFICATION: '暫無關鍵詞分類',
  KEYWORD_TOTAL_TIP: '共{count}個關鍵詞分類',
  CONVERSATION: '會話',
  COMMENT: '評論',
  BARRAGE: '彈幕',
  CLASSIFICATION_NAME: '分類名稱',
  SEMANTIC_CLASSIFICATION_TYPE: '所屬語義分類',
  KEYWORD: '關鍵詞',
  INCLUDE_KEYWORDS: '關聯關鍵詞',
  EXCLUDED_KEYWORDS: '屏蔽關鍵詞',
  CONFIRM_DELETE_KEYWORD_TIP: '確認刪除分類{name}及關鍵詞?',
  CONFIRM_DELETE_KEYWORD_SUB_TIP: '刪除後，內容中即使包含屏蔽關鍵詞也不貼語義分類，也不會做歸納統計',
  CONFIRM_ENABLE_KEYWORD_TIP: '確認啟用分類{name}及關鍵詞?',
  CONFIRM_ENABLE_KEYWORD_SUB_TIP: '啟用後，語義分類會在明天0點正式生效',
  CONFIRM_DISABLE_KEYWORD_TIP: '確認禁用分類{name}及關鍵詞?',
  CONFIRM_DISABLE_KEYWORD_SUB_TIP: '禁用後，內容中即使包含屏蔽關鍵詞也不貼語義分類，也不會做歸納統計',
  CONFIG_KEYWORD_RULE_TIP: '關聯關鍵詞裡的關鍵詞或動態規則至少配置一個',
  CONFIG_KEYWORD_RULE_REPEATION_TIP: '關聯關鍵詞或者屏蔽關鍵詞之間存在重復關鍵詞 「{keyword}」',
  PLEASE_ENTER_CATEGROY_NAME: '請輸入分類名稱',
  CATEGROY_NAME_VERIFICATION_TIP: '分類名稱只能包含中英文數字',
  CATEGROY_EXISTED: '分類已存在',
  AFFECT: '作用',
  RULE_CONFIG: '規則配置',
  INCLUDE_KEYWORDS_CONFIG_SUB_TIP: '內容中包含關聯關鍵詞則貼上語義分類',
  EXCLUDE_KEYWORDS_CONFIG_SUB_TIP: '內容中包含屏蔽關鍵詞則不貼語義分類',
  KEYWROD_VERIFICATION_RULE_TIP:
    '多關鍵詞以回車鍵換行分隔，最多可輸入100個關鍵詞，單個關鍵詞最多50個字符，僅支持中英文、數字，不區分大小寫',

  KEYWROD_VERIFICATION_RULES_LENGH_TIP: '最多添加100個規則',
  SINGLE_KEYWROD_RULE_TEXT_LENGH_TIP: '單條規則的長度最多50個字符',
  KEYWROD_RULE_TEXT_FORMATTER_TIP: '僅支持中英文、數字，不區分大小寫',

  KEYWROD_VERIFICATION_RULE_PLACEHOLDER: ' 一行一個，支持中英文、數字及符號',
  DYNAMIC_RULE: '動態規則',
  CONFIG_RULE_REQUIRED_TIP: '至少配置一條規則',
  CONFIG_RULE_ITEM_REQUIRED: '規則項需必填',
  SYSTEM_RULE: '系統規則',
  CUSTOM_RULE: '自定義規則',
  CUSTOM_RULE_NAME: '自定義規則名稱',
  PLEASE_SELECTED_RULE: '請選擇規則',
  PLEASE_ENTER_RULE_NAME: '請輸入自定義規則名稱',
  PLEASE_ENTER_REGULAR_EXPRESSION: '請輸入正則表達式',
  REGULAR_EXPRESSION_VERIFICATION: '正則表達式驗證',
  REGULAR_EXPRESSION: '正則表達式',
  PLEASE_ENTER_RIGHT_REGULAR_EXPRESSION: '請輸入正確的正則表達式',
  CLASSIFICATION_AND_DATA_FILLED_TIP: '分類名稱和樣例數據需要都填寫',
  SAMPLE_DATA: '樣例數據',
  PLEASE_ENTER_SAMPEL_DATA: '請輸入樣例數據',
  VERIFICATION_RESULT: '驗證結果',
  CLICK_TO_VIEIFY: '點擊驗證',
  CLICK_TO_REVALIDATION: '點擊重新驗證',
  PASS: '通過',
  NOT_PASS: '未通過',
  RULE_TYPE: '規則類型',
  RULE_NAME: '規則名稱',
  CREATE_KEYWORD: '新建語義分類',
  VEIW_KEYWORD: '查看語義分類',
  EDIT_KEYWORD: '編輯語義分類',

  //==============权限管理==============
  RESOURCE: '資源',
  ATTRIBUTE_SCOPE: '屬性范圍',
  OPERATE_TYPE: '操作類型',
  ORG_STRUCTURE: '組織架構',
  ACTION_ADD_USER: '添加用戶',
  ACTION_BATCH_REMOVE: ' 批量移除',
  RANDOM: '隨機',
  SELECT_NAME: '選擇{name}',
  ROLE: '角色',
  USER_GROUP: '用戶組',
  ROLE_NAME: '角色名稱',
  FUNCTION_PERMISSION: '功能權限',
  SUPPORT_CN_EN_NUMBER_UNLINE_TEXT_TIP: '支持中英文、數字及下劃線',
  SUPPORT_EN_NUMBER_UNLINE_TEXT_TIP: '支持中英文、數字及下劃線',
  COUNT_CN_EN_NUMBER_UNLINE_TEXT_REQUIRD_TIP: '最多{count}個字符，支持中英文、數字及下劃線',
  COUNT_CN_EN_NUMBER_UNLINE_SYMBOL_TEXT_REQUIRD_TIP: '最多{count}個字符，支持中英文、數字及符號',
  NAME_EXISTED: '{name}已經存在',
  FUNCTION_PERMISSION_SET_TIP: '操作權限對應的數據范圍，請在用戶組中設置',
  PERMISSION_CONFIG: '權限配置',
  USER_COUNT: '用戶數',
  USERGROUP_NAME: '用戶組名稱',
  BIND_USERGOUP: '關聯用戶組',
  OPERATION_PERMISSION: '操作權限',
  CONFIRM_DELETE_USER_CONTENT_TIP: '刪除用戶將無法找回並且無法登錄系統',
  CONFIRM_DELETE_ROLE_CONTENT_TIP: '刪除角色將無法找回並且用戶權限將被移除',
  CONFIRM_DELETE_USERGROUP_CONTENT_TIP: '刪除用戶組將移除相關用戶的權限',

  USERNAME: '用戶名',
  EMAIL: '郵箱',
  BIND_ROLE: '關聯角色',
  DATA_PERMISSION: '數據權限',
  CURRENT_GROUP: '所在用戶組',
  OVERVIEW_PERMISSION: '數據權限一覽',
  CONFIRM_DISABLE_USER: '確認是否禁用用戶?',
  CONFIRM_DISABLE_USER_CONTENT_TIP: '禁用用戶將無法登錄系統',
  GROUP_USERS: '組內用戶',
  USER_CONFIG: '用戶配置',
  ACTION_SAVE_AND_CONFIG_PERMISSION: ' 保存並配置權限',
  CONFIG_PERMISSION: '配置權限',
  CONFIG_USERGROUPS: '配置用戶組',
  PLEASE_CONFIG_NAME: '請配置{name}',
  CONFIG_PERMISSION_VALIDATE_TIP: '數據權限存在必填項目未填寫',
  OPERATOR_SYMBOL: '匹配符',
  EQUAL: '等於',
  NOT_EQUAL: '不等於',
  MODULE: '模塊',
  RESOURCE_OBJ: '資源對象',
  ROLE_CONFIG_ATTR_MISSING: '當前數據權限角色對象已被刪除，請重新配置。',
  NO_PERMISSION_VISIT: '無權限訪問',
  PLEASE_CONTACT_ADMINISTRATOR_TO_OBTAIN_PERMISSION: '請聯系管理員獲取權限',
  NO_PROMESSION: '暫無操作權限',

  //=================TAG管理===============
  SEARCH_TAG: '支持標簽名稱搜索',
  TAG_NAME: '標簽名稱',
  TAG_TYPE: '標簽類型',
  TAG_VALUE: '標簽值',
  DEL_TAG_CONFIRM_TIP: '是否刪除標簽，刪除後不可找回，並且對應的內容標簽將被移除?',
  TAG_OBJECT: '標簽對象',
  TAG_VALUE_TYPE: '單值/多值',
  AUTO_RULE: '自動規則',
  AUTO_RULE_CONFIG_TIP: '滿足如下條件（多個條件之間為「且」關系）',
  CHECK_RULE_CONFIG_ERROR_TIP: '存在未配置的選項',
  CONDITION: '條件',
  OR: '或',
  AND: '且',
  TAG_SETTING_RULE: '標簽值規則',
  AUTO_RULE_CONFING_TIP: '滿足以下條件將自動打上對應標簽',
  UNKNOWN_NAME: '未命名',
  NAME_REQUIRED: '{name}需必填',
  UPATE_TAG_CONFIRM_TIP: '是否確認更新標簽內容，確認後系統將自動更新相關內容標簽?',
  AUTO: '自動',
  MANUAL: '手動',
  SINGLE_VALUE: '單值',
  MULTIPLE_VALUE: '多值',
  TAG_ANALYLSIS: '標簽統計分析',
  CONTENT: '內容',
  ANALYLSIS: '統計',
  COVER_RATE: '覆蓋率',
  COVER_COUNT: '覆蓋數量',

  EXCELENT_CASE: '優秀案例',
  EXCELENT_VIDEO: '優秀視頻',
  EXCELENT_LIVE: '優秀直播',
  CONFIRM_DELETE_EXCELENT_CASE_TIP: '取消後將取消該優秀案例的收藏!',
  RECOMADATION_REASION: '推薦理由',
  JOIN_EXCELLENT_CASE: '加入優秀案例',
  JOIN_EXCELLENT_CASE_ALREADY: '當前素材已經加入優秀案例',
  OPERATIONAL_MATERIAL: '運營素材',
  INDUSTRY_HOTSPOT: '行業熱點',
  AUTOMOTIVE_RANKING: '汽車總榜',
  HOTSPOT_EVENT: '熱點事件',
  POPULAR_VIDEO: '爆款視頻',
  HOT_TOPIC: '熱門話題',
  INDUSTRY_COLLECATION: '行業收藏',

  //热点
  CAR_OVERALL: '汽車總榜',
  COMMENT_CAR: '評車',
  PLAY_CAR: '玩車',
  USE_CAR: '用車',
  DRIVER: '駕考',
  TYPE: '類型',
  COLLECT: '收藏',
  COLLECTED: '已收藏',

  CONFIRM_DELETE_INDUSTIAL_COLLECT_TIP: '取消後將取消該優秀案例的收藏!',
  TOPIC_NAME: '話題名稱',
  HEART_TREND: '熱度趨勢',
  HEART_VALUE: '熱度值',
  HIGHEST_HEART_VALUE: '最高熱度值',
  RELATED_VIDEOS: '相關視頻數',
  VIDEO_NUMS: '視頻量',
  AVG_PLAY_NUMS: '稿均播放量',
  AVATAR: '頭像',
  LIST_MONTH_TOTAL_TIMES: '近一個月在榜次數',
  INFLUENCE_INDEX: '影響力指數',
  FOLLOW_RATE: '漲粉率',
  CLICK_RATE: '點擊率',
  LIKE_CNT: '新增點贊量',
  PALY_CNT: '新增播放量',
  FOLLOW_CNT: '粉絲量',
  CAR_MARKET: '汽車買賣',
  CAR_USAGE: '汽車使用',
  CAR_DISPLAY: '汽車展示',
  NOT_CURRENTLY_SUPPORTED: '暫不支持',
  CLICK_VIEW_MORE: '點擊查看詳情',
  CLICK_CANCEL_COLLECT: '點擊取消收藏',
};
