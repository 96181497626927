export default {
  APP_NAME: 'Social Content Growth Matrix',
  LOGIN: 'Sign in',
  LOGIN_NAME: 'Login Name',
  PASSWORD: 'Password',
  USERNAME_INPUT_TIP: 'Type username',
  PASSWORD_INPUT_TIP: 'Type password',
  VERIFICATION_CODE_INPUT_TIP: 'Type captcha code',
  ERROR_USER_PWD_TIP: 'Username or password is incorrect',

  EXIT: 'Sign out',
  MULTILINGUAL: 'Language',
  CHANGE_PASSWORD: 'Password',
  CONFIRM_PASSWORD: 'Confirm Password',
  NEW_PASSWORD: 'New password',
  OLD_PASSWORD: 'Original password',
  PWD_RULE_TIP: 'Passwords are at least 8 characters long and must contain numbers, letters or special characters that match at least two rules',
  CONFRIM_PWD_NOT_SAME_TIP: 'Inconsistent password entry!',
  PAGINATION_INFO_TIP: 'Total {total} record(s), currently showing {start} - {end}.',
  ACTION_SUBMIT: 'Submit',
  ACTION_CONFIRM: 'Confirm',
  ACTION_DELETE: 'Delete',
  ACTION_REMOVE: 'Remove',
  ACTION_SAVE: 'Save',
  ACTION_SAVA_DRAFT: 'Save as Draft',
  ACTION_SUBMIT_PUBLISH: 'Submit for publication',
  ACTION_DOWNLOAD: 'Download',
  ACTION_DISABLE: 'Disabled',
  ACTION_ENABLE: 'Enabled',
  ACTION_DOWNLOAD_DATA: 'Download',
  ACTION_VIEW: 'View',
  ACTION_EDIT: 'Edit',
  ACTION_OPERATE: 'Operation',
  ACTION_SEARCH: 'Search',
  ACTION_NEW: 'New',
  ACTION_CANCEL: 'Cancel',
  ACTION_ADD: 'Add',
  LOGIN_PWD: 'Login password',
  COPY: 'Copy',
  ACTION_RENAME: 'rename',
  ACTION_ADD_NAME: 'Add {name}',
  STATUS: 'State of affairs',

  OPERATION_SUCCESS: 'Operation successful',
  DELETE_SUCCESS: 'Delete successful',
  CREATE_SUCCESS: 'Created successfully',
  CHANGE_PASSWORD_SUCCESS: 'Password modification successful',
  EDIT_SUCCESS: 'Edit successful',
  OPERATION_FAILURE: 'Operation failed',
  DELETE_FAILURE: 'Failed to delete',
  VALIDATION_EXCEIPTION: 'validate anomalies',
  NO_PREMISSION: 'You do not have permission to operate this page at this time!',
  NO_DATA: 'No data available',
  CREATE_TIME: 'Creation time',
  CREATOR: 'Creator',

  CONFIRM_DELETE_NAME_TIP: 'Are you sure you want to delete {name}?',

  //============组件========
  NUM_MONTH: '{num} month(s) ',
  NUM_WEEK: '{num} week(s) ',
  NUM_DAY: '{num} day(s) ',
  NUM_HOUR: '{num} hour(s) ',
  NUM_MINUTE: '{num} min(s) ',
  NUM_SECOND: '{num} sec(s) ',
  JUST_NOW: 'Just',
  DATE_BEFORE: 'Ago',
  DATE_AFTER: 'After',
  ORGANIZATIONAL_ACCOUNT: 'Organizational account',
  PLAY_VIDEO: 'Play video',
  CLICK_TO_PC_WATCH_VIDEO: 'Click to PC version to view the video',
  CLICK_TO_WATCH: 'Click to watch',
  ACCOUNT: 'Account',
  APPLY: 'Apply',
  SELECTED_NUM_COLUMNS: 'Selected {num} column',
  RESET_TO_DEFAULT: 'Restore default',
  CLEAR_EMPTY: 'Empty',
  ABOVE_LIST_CANNOT_BE_MODIFIED: 'The above columns are not modifiable',
  ERROR_LOADING_COMPONENT_REFRESH_PAGE: 'Error loading component, please refresh the page and try again.',
  PLEASE_SELECT_AFFILIATED_ORGANIZATION: 'Please select organization',
  CUMULATIVE: 'cumulatively',
  AUTHORIZATION_SUCCESSFUL: 'Authorization successful',
  YESTERDAY: 'Yesterday',
  PLAY_HISTORY_LIVE_VIDEO: 'Play historical live videos',
  AUTO_LOCATE_CITY: 'Auto address',
  MANUALLY_FILL_IN_REGION: 'Manual address',
  SOURCE_PAGE: 'Source Page',
  COMPONENT_TYPE: 'Component type',
  SERVICE_CLUE_SOURCE: 'Service ID clue source',
  CALL_HISTORY: 'Call history',
  LEAD_PROVINCE_ORIGIN: 'Leads province of origin',
  LEAD_CITY_ORIGIN: 'Leads hometown city',
  ADVERTISING_GROUP_ID: 'Advertising Group ID',
  LANDING_PAGE_ID: 'Landing page ID',
  ADVERTINSING_CREATIVAITY_ID: 'Advertising Creative ID',
  ADVERTINSING_CREATIVAITY: 'Advertising creativity',
  ADVERTINSING_GROUP_NAME: 'Advertising Group Name',
  LANDINGPAGE_NAME: 'Landing page name',
  PURPOSE_INFO: 'Intentional information',
  INTENDED_GOODS: 'Intended products',
  INREND_PROVINCE_NAME: 'Leads user intention province',
  INREND_CITY_NAME: 'Leads user intention city',
  STORE_PROVINCE: 'Store Information - Province',
  STORE_CITY: 'Store Information - City',
  STORE_NUMBER: 'Store Information - Store Code',
  STORE_INFO_NAME: 'Store Information - Store Name',
  FORM_ID: 'Form ID',
  FORM_NAME: 'Form Name',
  PAY_ORDER_NO: 'Payment form order number',
  PAY_ORDER_STATE_DES: 'Payment Form Order Status Description',
  COUPON_ID: 'Card Voucher Component - Card Voucher ID',
  COUPON_NAME: 'Card Coupon Component - Card Coupon Name',
  COUPON_NUMBER: 'Voucher Components - Voucher Codes',
  COUPPON_PHONE: 'Card Coupon Component - Cell Phone Number',
  COUPON_EXPIRE: 'Card Coupon Component - Expiration Time',

  NUMBER_OF_LEADS: 'Left leads',
  ADVERTISEMENT_INFORMATION: 'Advertising',
  ADVERTISER_ID: 'Advertiser id',
  ADVERTISER_NAME: 'Advertiser name',
  AD_CAMPAIGN_ID: 'Advertising plan id',
  AD_CAMPAIGN_NAME: 'Advertising plan name',
  CREATIVE_ID: 'Creative id',
  ADVERTISEMENT_ID: 'Promotion id',
  ADVERTISEMENT_NAME: 'Promotion name',
  COMPANY_INFORMATION: 'Enterprise',
  OTHER_INFORMATION: 'Other',
  LEAD_ID: 'Leads id',
  IS_OFFSITE_CLUE: 'Ben/Different Clues',
  LOCAL_LOCATION: 'Local',
  OTHER_AREA: 'Not local',
  UNCERTAIN: 'Impossible judge',
  CAR_INFORMATION: 'Automotive',
  INTENDED_CAR_SERIES: 'Aintention car series',
  INTENDED_CAR_MODEL: 'Aintention car model',
  PURCHASE_INTENTION: 'Car intention',
  OTHER: 'Other',
  OPERATOR_MOBILE_NUMBER: 'Mobile number',
  REGION_AREA: 'Region',
  REGION_NAME: 'Region name',
  DEALER: 'Dealer',
  DEALER_NAME: 'Dealer name',
  EMPLOYEE: 'Staff',
  EMPLOYEE_NAME: 'Staff name',
  STORE: 'Store',
  STORE_INFO: 'Store Information',
  STORE_NAME: 'Store Name',
  STORE_CODE: 'Store Code',
  INFLUENCER: 'Influencer',
  INFLUENCER_NAME: 'Influencer',
  DEALER_CODE: 'Dealer code',
  TIKTOK_ACCOUNT_INFORMATION: 'Douyin account',
  ACCOUNT_INFORMATION: 'Account info',
  TIKTOK_NICKNAME: 'Douyin nickname',
  PLATFORM_TYPE: 'Media',
  TOTAL_VIDEOS: 'Videos',
  NEW_FANS: 'New fans',
  NET_FOLLOW_FANS: 'Net increase in fans',
  NEW_LEADS: 'New leads added',
  HOMEPAGE_VISITORS: 'Website Traffic',
  SHORT_VIDEO: 'Short video',
  NEW_VIDEOS: 'Added Videos',
  NEW_PLAY: 'Added Plays',
  NEW_LIKES: 'Added Likes',
  NEW_COMMENTS: 'Added Comments',
  NEW_SHARES: 'Added Shares',
  DURATION_MINUTES: 'Duration (min.)',
  AUDIENCE_NUMBER: 'Live watch UV by room',
  AVERAGE_STAY_DURATION_MINUTES: 'Average stay time (min.)',
  AVERAGE_STAY_DURATION_SECONDES: 'Average length of stay (seconds)',
  AVERAGE_STAY_DURATION: 'Average duration of stay',
  LEAD_GENERATION: 'Retain capital',
  LIVE_SCENE_LEAD_NUMBER: 'Live period feiyu total leads UV',
  LIVE_ROOM_FORM_SUBMITTED_LEAD_COUNT: 'Feiyu live leads count',
  ORANGE_SITE_LEAD_COUNT: 'Feiyu orange leads count',
  ORGANIC_TRAFFIC_LEAD_COUNT: 'Feiyu natural leads count',
  TOTAL_LEADS_COUNT: 'Total leads',
  TOTAL_LEADS: 'Total retention',
  VIDEO_LEADS: 'Video leads',
  LIVE_LEADS: 'Live leads',
  MESSAGE_LEADS: 'Message leads',
  HOMEPAGE_LEADS: 'Mainpage leads',
  ENTER_MESSAGE_SESSION_NUMBER: 'Private Message Enter UV',
  MESSAGE_SESSION_INITIATOR_NUMBER: 'Receive message UV',
  MESSAGE_LEAD_QUANTITY: 'Feiyu message leads UV',
  MESSAGE_LEAD_CONVERSION_RATE: 'Feiyu message leads UV CVR',
  ACCEPTED_MESSAGES: 'Receive messages',
  MISSED_MESSAGES: 'Miss replay messages',
  THREE_MIN_REPLY_RATE: '3-minute replay CVR',
  ADVERTISEMENT: 'Advertisement',
  AD_DIRECT_LEAD_QUANTITY: 'Advertising - Direct Leads Volume',
  AD_INDIRECT_LEAD_QUANTITY: 'Advertising - Indirect Leads Volume',
  AD_SPEND: 'Advertising consumption',
  AD_LEAD_COST: 'Native ad leads cost',
  DIRECT_LEAD_COST: 'Direct leads cost',
  AD_IMPRESSIONS: 'Native ad show cnt',
  AD_CLICK_THROUGH_RATE: 'Native ad ctr',
  NATIVE_AD_MANAGEMENT_NEW_FANS: 'Native ad dy follow',
  NATIVE_AD_MANAGEMENT_SHORT_VIDEO_SHARES: 'Native ad dy share',
  NATIVE_AD_MANAGEMENT_SHORT_VIDEO_COMMENTS: 'Native ad dy comment',
  NATIVE_AD_MANAGEMENT_SHORT_VIDEO_LIKES: 'Native ad dy like',
  NATIVE_AD_MANAGEMENT_HOMEPAGE_VISITS: 'Native ad dy home visited',
  NATIVE_AD_MANAGEMENT_ENTER_MESSAGE_PEOPLE: 'Native ad message action',
  LP_SCREEN_LIVE_WATCH_UV_TOTAL: 'Live audience count',
  LIVE_STREAM_THEME: 'Live Streaming Topics',
  EXPOSURE_UV: 'Number of bubble exposures',
  EXPOSURE_CLICK_UV: 'Number of Bubble Hits',
  COLLECTED_LEAD_NUM: 'Number of leads collected',
  EXPOSURE_CONVISION_RATE: 'Exposure Click Conversion Rate',
  LIVE_DIRATION: 'Length of live broadcast (minutes)',
  XHS_ACCOUNT_NICKNAME: 'RED nickname',
  NEW_IMAGE_TEXT_FANS_COUNT: 'New fans added through graphics and text',
  INCREASED_FANS_NUM_FROM_VIDEO: 'Video adds new fans',
  INCREASED_FANS_NUM_FROM_NOTE: 'Notes to add new fans',
  IMAGE_TEXT_TOTAL: 'Total amount of graphics',
  NOTE_TOTAL: 'Total notes',
  XHS_ACCOUNT: 'RED Hao',
  ADD_PICTURE_TEXT: 'Add new graphics and text',

  ADD_PLAY_FROM_PICTURE_TEXT: 'Graphic added to watch',
  ADD_COMMENT_FROM_PICTURE_TEXT: 'Graphic added comments',
  ADD_LIKE_FROM_PICTURE_TEXT: 'Add Likes to Graphic',
  ADD_SHARE_FROM_PICTURE_TEXT: 'Graphic added for sharing',
  ADD_FAV_FROM_PICTURE_TEXT: 'Graphic additions to favorites',
  ADD_RECOMMENDS: 'New Recommendation',

  TURN_ON_NAVIGATION: 'Enable navigation',
  TURN_OFF_NAVIGATION: 'Turn off navigation',
  HOME_PAGE: 'Home page',
  COPY_SUCCESSFUL: 'Copy successful',

  //=============平台=========
  PLATFORM: 'Platform',
  PLATFORM_DOUYIN: 'Douyin',
  PLATFORM_KUAISHOU: 'AAuto Quicke',
  PLATFORM_XIHONGSHU: 'RED',
  PLATFORM_SHIPINGHAO: 'Video account',

  //=============菜单==============
  WORKBENCH: 'Workbench',
  USER_CENTER: 'User center',
  DATA_VIEW: 'Data overview',
  DATA_STATISIICS: 'Data statistics',
  PLATFORM_ACCOUNT: 'Platform account',
  LIVE_MANAGE: 'Live stream',
  LIVE_LIST: 'Live stream',
  VIDEO_OPERATIONS: 'Video',
  WORK_MANAGEMENT: 'Short Video',
  WORK_PUBLISH: 'Creation release',
  INTERACTIVE_MANAGEMENT: 'Interactive',
  CONVERSATION_MANAGEMENT: 'Session',
  SESSION_AGGREGAITION: 'Session aggregation',
  HISTORICL_CONVERSATION: 'Historical conversation',
  COMMENT_MANAGEMENT: 'Comment',
  LIVE_BARRAGE_MANAGEMENT: 'Live danmu',
  CUSTOMER_ACQUISITION_MANAGEMENT: 'Customer acquisition',
  CLUE_MANAGEMENT: 'Leads',
  HIEGH_POTENTIAL_USERS: 'High potential user',
  SYSTEM_CONFIGATION: 'Configuration',
  AUTHORIZED_ACCOUNT: 'Authorized account',
  SEMANTIC_CLASSIFICATION: 'Semantic classification',
  ORGANIZATIONAL_MANAGEMENT: 'Organizational',
  PERMISSION_MANAGE: 'Permission',
  PERMISSION_USER: 'Users',
  PERMISSION_USERGROUP: 'User groups ',
  PERMISSION_ROLE: 'Roles',
  TAG: 'Tag',
  TAG_MANAGEMENT: 'Tag management',

  //=============表单提示==============
  PLEASE_SELECT: 'Please select',
  PLEASE_SELECT_MULTIPLE_NAME: '-Please select {name} (multiple choices)-',
  PLEASE_SELECT_SINGLE_NAME: '-Please select {name}-',
  PLEASE_TYPE: 'Type text',
  PLEASE_TYPE_NAME: 'Type {name}',
  PLEASE_TYPE_NUMBER: 'Type a number',
  BASIC_INFO: 'Basic',
  CREATE_NAME: 'Create {name}',
  EDIT_NAME: 'Edit {name}',
  VIEW_NAME: 'View {name}',

  //=============账户==============
  FANS: 'Fans',
  VIDEO: 'Video',
  LIVE: 'Live',
  LIVT_BROADCAST_TOTAL: 'Live broadcasts',
  TOTAL_NEW_FANS: 'New fans',
  TOTAL_LOST_FANS: 'Lost fans',
  OVERVIEW: 'Overview',
  TREND_ANALYSIS: 'Trend analysis',
  FAN_PORTRAIT: 'Fan Portrait',
  USER_ANALYSIS: 'User analysis',
  GENDER_DISTRIBUTION: 'Gender distribution',
  AGE_DISTRIBUTION: 'Age distribution',
  VIEW_REPORT: 'View report',
  COMMENT_TIME: 'Comment time',
  NICKNAME: 'User nickname',
  AFFILIATION_ORG: 'Organization',
  ACCOUNT_NAME: 'Account name',
  AUTHORIZE_ACCOUNT_NAME: 'Authorized account',
  ACCOUNT_REPORT_AUDIENCE_ACTIVE_TIME: 'Audience active time',
  ACCOUNT_REPORT_PROVINCE_DISTRIBUTION: 'Permanent provinces Top 10',
  ACCOUNT_REPORT_CROWD_DISTRIBUTION: 'Distribution of eight populations',
  CROWD_DISTRIBUTION: 'Distribution of the population',
  REGION_REPORT_DISTRIBUTION_TOP: 'Regional Distribution Top10',
  INTEREST_REPORT_DISTRIBUTION_TOP: 'Interest Distribution Top10',
  INTEREST_DISTRIBUTION: 'Interest distribution',
  ACCESS_DEVICES: 'Accessing devices',
  ACTIVE_TIME: 'Active time',
  SYSTEM_DISTRIBUTION: 'System distribution',
  DATA_TRANDS: 'Data trends',
  FUNS_SOURCE: 'Source of fans',
  PROVINCE_DISTRIBUTION: 'Provincial distribution',
  CITY_DISTRIBUTION: 'Urban distribution',
  LIVE_ROOM_ID: 'Room id',
  LIVE_ROOM_ID_TIP: 'Please enter numbers, separated by commas.',
  LIVE_TIME: 'Start time',
  RANKINGS: 'Ranking',
  PROVINCE: 'Province',
  PEOPLE_COUNT: 'Number of people',
  TIME_COUNT: 'Frequency',
  PERCENTAGE: 'Proportion',
  NOTE: 'Notes',

  //=============交互管理==============
  INTERACTIVE_COMMENT_DATA_TIPS: 'Only pop-ups that are not self-explanatory or do not relate to the official rules will be displayed in each room.',
  BULLET_CONTENT: 'Danmu content',
  KEYWORD_CLASSIFICATION: 'Keyword classification',
  KEYWORD_HIT: 'Hit keywords',
  PUBLISH_ACCOUNT: 'Account',
  ORGANIZATION_TYPE: 'Organizational type',
  ACTION_LIVE_REPLAY: 'Playback',
  ACTION_VIEW_COMMENT: 'View comments',
  ACTION_MORE_BARRAGE: 'More',
  NO_COMMENT: 'There are currently no comments available',
  COMMENT_COUNT_TIP: 'A total of {count} comments',
  DATA_UPDATE_TIME_TIP: 'Last Updated: {syncTime}',
  AFFILIATION_PLATFORM: 'Affiliated platform',
  SEND_TIME: 'Sending time',
  ALL: 'All',
  UNCLASSIFIED: 'Unclassified',
  COMMENT_CONTENT: 'Content',
  WORK: 'Creation',
  WORK_ACCOUNT: 'Creation account',
  CONVERSATION_CARD_TIP: '[Card Message] This message type is not supported at this time Please go to the app side to view it',
  CONVERSATION_NOT_SUPPORT_TIP: 'This message type is not supported at this time Please go to the app side to view',
  CONVERSATION_INFO: 'Session information',
  CONVERSATION_CURRENT: 'This session',
  CONVERSATION_ALL: 'All Sessions',
  CONVERSATION_SEARCH_TIP: 'Search session content',
  TIKTOK_ACCOUNT: 'Douyin account',
  MATRIX_ACCOUNT_NAME: 'Matrix account name',
  ADDRESS: 'Address',
  USER: 'Users',
  SESSION_RECORDS: 'Session recording',
  MESSAGE_COUNT: 'Messages',
  SESSION_TIME: 'Begin time',
  SESSION_END_TIME: 'End time',
  SESSION_DURATION: 'Duration',
  SESSION_ACCOUNT: 'Account',
  NO_CONVERSATIONS: 'No Session',
  TOTAL_CONVERSATIONS_COUNT: 'Total {count} sessions',
  TOTAL_COUNT: 'A total of {total} items',
  NO_NOME_INFOMATION: 'No further information',
  CUSTOMER_READY_TALK_NO: 'Customer initiated session, session number',
  SELLER_FINISH_TALK_NO: 'Customer Service End Session, Session Number',
  SHARE_VIDEO: 'Share Video',
  SEND_EMOJI: 'Send an emoticon',

  //=============获客管理==============
  SOURCE_TIKTOK_ACCOUNT: 'Source douyin',
  SOURCE_TIKTOK_NICKNAME: 'Source douyin nickname',
  SHIPINGHAO_NICKNAME: 'Video account nickname',
  SHIPINGHAO_ACCOUNT: 'Video account',
  CLUE: 'Leads',
  CLUE_TYPE: 'Leads type',
  CLULE_ACCOUNT: 'Leads account',
  INTERACTION_TYPE: 'Action type',
  CLUE_CHANNEL: 'Leads channels',
  FLOW_TYPE: 'Traffic type',
  NAME: 'Name',
  CLUE_CRATE_TIME: 'Leads creation time',
  LAST_ACTION_TIME: 'Action time',
  CUSTOM_COLUMNS: 'Custom columns',
  LIVE_BROADCAST_ROOM_BARRAGE: 'Live room danmu',
  VIDEO_COMMENT: 'Video comments',
  VIEW_DETAIL: 'View details',
  RERA: 'Territory',
  CLUE_DETAIL_COMMENT_TPL: '{nickName} via {accountName} at {mediaName} - "{title}" Leave a comment:"{content}"',
  CLUE_DETAIL_BULLET_TPL: '{nickName} sends popups via {accountName} in {mediaName} live stream: "{content}"',
  CLUE_DETAIL_SESSION_TPL: '{nickName} initiated {mediaName} private conversation via {accountName}: "{content}"',
  CLUE_DETAIL_CLUE_TPL: '{nickName} via {accountName}, leaving funds in {mediaName}: "{content}"',
  PRIVATE_LETTER: 'Private message',
  VIEW_CLUE_DETAIL: 'View leads details',
  TELEPHONE: 'Telephone',
  TRAFFIC_SOURCE: 'Source of traffic',
  INTERACTION_RECORDS: 'Interactive recording',
  INTERACTIVE_TIME: 'Interaction time',
  INTERACTION_ACCOUNT: 'Interactive account',
  CLUE_BASIC_INFO: 'Leads basic information',
  USER_TIKTOK_ACCOUNT: 'User douyin number',
  USER_TIKTOK_NICKNAME: 'User douyin nickname',
  USER_NICKNAME: 'User account nickname',
  USER_ACCOUNT: 'User account',
  CUSTOMER_CITY: 'Customer City',
  JOB_CANDIDATES: 'Job application',
  CITIES: 'City',
  CALL_STATUS: 'Call status',
  USER_INFO: 'User Information',

  AGE: 'Age',
  GENDER: 'Gender',
  WECHAT: 'WeChat',
  BIRTHDAY: 'Brthday',
  CLUE_SOURCE_INFO: 'Leads source information',
  IS_FUNDS: 'Contact Information',
  FUNDS_YES: 'Provided',
  FUNDS_NO: 'Not Provided',
  CITY_AREA: 'City name',
  CLUE_INFO: 'Leads information',
  LATEST_CLUE: 'Latest leads',
  COMMENT_COUNT: 'Comments count',
  NUM: 'Number',
  KS_ACCOUNT: 'Kuaishou account',
  KS_NICKNAME: 'Kuaishou nickname',
  COLLECTION_TIME: 'Collection time',
  FILTER_RESULT: 'Cleaning results',
  CLUE_PREGRESS: 'Leads progress',
  TRANSFORM: 'Transform',

  //============系统组织结构==========
  CALL_NAME: 'Name',
  CODE: 'Coding',
  ORG_TOTAL_TIP: 'A total of {count} {title}',
  DELETE_ORG_CONFIRM_TIP: 'Are you sure to delete the organizational structure "{name}"?',
  DELETE_ORG_CONFIRM_SUB_TIP: 'Cannot be retrieved after deletion',

  ORG_NAME_EXISTED: 'Organizational name already exists',
  INPUT_TEXT_VALIDATATION_TIP: 'Up to {count} characters, supports English, numbers and symbols.',
  FULL_ADDRESS: 'Detailed address',

  //================= 矩阵运营 =================
  PICTURE_NOTE: 'Image and text',
  PICTURE: 'Picture',
  LIST_WORKS: 'Video List',
  COVER: 'Cover',
  TITLE_OF_WORK: 'Title',
  PUBLISH_TIME: 'Publishing time',
  ACTION_COMMENT: 'Comment',
  ACTION_VIEW_INTERACTIVE: 'View interactive data',
  TOTAL_NUMBER_OF_WORKS: 'Total creations',
  STATIST_UNIT_TIAO: ' ',
  OPERATIONAL_DATA: 'Operational data',
  INTERACTIVE_EFFECT_OF_WORKS: 'Interactive effects of creations',
  WORK_INDICATORS: 'Video Indicators',
  DRAFT: 'Draft',
  PUBLISHED: 'Published',
  REMOVED: 'Removed',
  REPLY: 'Reply',
  CLOSE_REPLY: 'Close reply',
  COMMENT_PLACEHOLDER_TIP: 'Love the comments. Say something nice.',
  EMOTICON: 'Expression',
  SEND: 'Send',
  SELECT_EMOTICON: 'Choose emoji',
  SUMMARY_OF_COMMENTS: 'Comment Summary',
  COMMENT_TIME_ID: 'Time/ID',
  CLASSIFICATION: 'Classification',
  HIDE_ALL_REPLIES: 'Put away all replies',
  EXPAND_NUM_REPLIES: 'Expand {num} replies',
  NO_MORE_DATA: 'No more.',
  AFTER_THE_VIDEO_IS_RELEASED: 'After video release',
  PLAY_COUNT_TODAY: 'Daily views',
  SHARE_COUNT_TODAY: 'Daily sharings',
  COMMENT_COUNT_TODAY: 'Daily comments',
  LIKE_COUNT_TODAY: 'Like it on that day',
  FAV_COUNT_TODAY: 'Daily recommendation',
  FOLLOW_COUNT_TODAY: 'Follow on the same day',

  PLAY_TODAY: 'Daily Views',
  LIKE_TODAY: 'Daily Likes',
  SHARE_TODAY: 'Daily Shares',
  COMMENT_TODAY: 'Daily Replies',
  DATE: 'Date',
  LAST_NUM_DAYS: 'Nearly {num} days',
  LIKE_COUNT_TOTAL: 'Accumulated likes',
  SHARE_COUNT_TOTAL: 'Cumulative Shares',
  COMMENT_COUNT_TOTAL: 'Cumulative Comments',
  WORKS_COUNT_TOTAL: 'Cumulative total of works',
  MATRIX_OPERATION_STATISTICS_TIP:
    '1. Statistics are updated in real time after the first authorization of the account; 2. Trend and breakdown data need to be displayed on the day after the user first authorizes the application, and only data for videos created within thirty days can be provided.',
  WORK_DETAILS: 'Creation details',
  COMPLETION_RATE: 'Completion rate',
  INDICATOR_DATA: 'Indicator data',
  LIKE_NEGATIVE_NUM_TIP: 'When the number of likes is negative, it may be due to the user canceling likes.',
  AVG_PLAY_DURATION: 'Average PT',
  LIKE: 'Like',
  RECOMMEND: 'Recommendation',
  FELLOW: 'Follow',
  SHARE: 'Share',
  PLAY: 'Play',
  LIKE_COUNT_TOAL: 'Accumulated likes',
  RECOMMEND_COUNT_TOTAL: 'Accumulated recommendations',
  COMMENT_COUNT_NUM_TOTAL: 'Accumulated comments',
  FOLLOW_COUNT_TOTAL: 'Accumulated followers',
  SHARE_COUNT_NUM_TOTAL: 'Accumulated sharing',
  FAVORITE_DAILY_NUM: 'Number of daily favorites',
  VIEWS_COUNT_TOTAL: 'Accumulated views',
  CUMULATIVE_WATCH_PV: 'Cumulative number of views',
  NOTE_COUNT_TOTAL: 'Total cumulative notes',
  VIDEO_COUNT_TOTAL: 'Total cumulative video volume',
  IMAGE_NOTE_COUNT_TOTAL: 'Total cumulative graphic volume',
  LIKED_TOTAL: 'Accumulated likes',
  COLLECTION_TOTAL: 'Accumulated Collections',
  BARAGE_TOTAL: 'Accumulated barrage count',
  INCREASING_NOTES_TOTAL: 'Cumulative number of notes up',
  INCREASING_FUNS_TOTAL: 'Cumulative Direct Fans',
  NOTE_TYPE: 'Note type',
  VIEW_NUM: 'Viewing volume',
  COLLECTIONS_NUM: 'Number of favorites',
  BULLET_COMMENTS_NUM: 'number of catapults',
  INCREASING_FOLLOWERS: 'Directly increase the number of fans',
  ITEM_CLUE_COUNT: 'Video retention',
  LIVE_CLUE_COUNT: 'Live streaming retention',
  OTHER_CLUE_COUNT: 'Other retained funds',
  CAPTION_REQUIRED_TIP1: 'Brief description of the work, supports up to 1000 text inputs',
  CAPTION_REQUIRED_TIP2: 'Title and synopsis of the work, supports up to 1000 text inputs',

  //===========作品发布======
  STATUS_UNPUBLISHED: 'To be released',
  STATUS_PUBLISHING: 'Posted in',
  STATUS_PUBLISHE_SUCCESS: 'Successfully published',
  STATUS_PUBLISHE_FAILURE: 'Publishing failed',
  APPLICATION_INFO: 'Application Information',
  LINK_URL: 'Link address',
  PLEASE_SELECT_APPLICATION: 'Please select an application',
  MINI_APP: 'applet',
  SELECT_MINI_APP: 'Select Mini Program',
  PLEASE_COPY_DY_APP_LINK: 'Please copy and paste the link of Tiktok applet',
  PERMISSION_SETTING: 'Permission settings',
  ALLOW_SAVING_VIDEO: 'Allow saving videos',
  WORK_INTRODUCTION: 'Introduction to the work',
  WORK_INTRODUCTION_TYPE_INPUT_TIP: 'Add a brief description of the work, up to {num} text inputs',
  VIDEO_MOUNTING: 'Video Mount',
  PLATFORM_NOT_CONFIGED_TIP: 'Platform information is not configured!',
  UNMONTING: 'Unmounted',
  VIDEO_MOUNTING_TIP: 'If you set up a video mount, the same content mount information posted by different accounts will be the same.',
  SEND_ACCOUNT_NUM_TIP: 'Total number of accounts selected for sending {num}',
  POSITON_SETTING_TIP: 'If you set the current location, the address information of the same content posted by different accounts will be the same',
  VIDEO_TITLE: 'Video Title',
  VIDEO_TITLE_TIP: 'Short headlines appear in scenarios such as search, topics, events, locations, subscription messages, discovery page red dots, and more.',
  VIDEO_TITLE_PLACEHOLDER: 'Summarize the main points, 6-16 characters recommended',
  ORIGINAL: 'Innovation',
  VIDEO_ORIGINAL_FIELD_TIP: 'Personal numbers do not support originality',
  VIDEO_ORIGINAL_FIELD_TEXT_DESCRIBE: 'Videos less than 5 seconds in length and graphic content are not eligible for the original statement.',
  UPLOAD_VIDEO_FILE_TIP: 'Uploading videos only supports single videos in the format of mp4, mov video files!',
  UPLOAD_VIDEO_DURATION: 'Uploading a video with a playback duration of no more than 15 minutes is required.',
  UPLOAD_VIDEO_MAX_SIZE_TIP: 'The size of the uploaded video file cannot exceed 128MB',
  CLICK_TO_UPLOAD: 'Click to upload',
  FILE_UPLOAD_REQUIRD_TIP:
    'Support mp4, webm format The size of the uploaded video file is more than 128MB, and the length of the video can not be more than 15 minutes It is recommended to upload a version of the video with 16:9, and the resolution is 720p (1280x720) and above.',
  FILE_UPLOAD_EXCEEDED: 'Maximum number of uploads exceeded',
  PUBLISH_SINGLE_IMG_REQUIRED: 'Maximum support for a single image 20M',
  PUBISH_IMG_WIDTH_HEIGHT_REQUIRED: 'The aspect ratio of graphics is recommended to be no more than 1:2',
  PUBISH_IMG_REQUIRED:
    'Support common image formats, not support gif format for the time being Maximum support for a single image is 20M, each time upload a maximum of 10 images The aspect ratio of the graphic is recommended to be no more than 1:2.',
  RESOUTION_TRTIO_REQUIRED_TIP: 'The resolution is too low, please upload a picture with a resolution greater than 324*432',
  VERTICAL_SCREEN: 'Vertical screen',
  LANDSCAPE_SCREEN: 'Landscape screen',
  COVER_IMG_SCREEN_REQUIRED: 'Supports JPEG, PNG, and JPG formats, with a maximum support of 10MB for RED image and text publishing and video number image and text publishing. Cover upload is currently not supported',
  TASK_NAME: 'Task Name',
  CONTENT_SETTING: 'Content settings',
  PUBLISH_TIME_REQUIRED_TIP: 'The publishing time cannot be earlier than the current time',
  CONTENT_TYPE: 'Content type',
  PUBLISH_TYPE: 'Posting Type',
  PUBLISH_VIDEO: 'Publish videos',
  PLEASE_UPLOAD_VIDEO: 'Please upload and publish a video',
  UPLOAD_PICTURE: 'Upload a picture',
  UPLOAD_COVER: 'Upload cover',
  RE_UPLOAD: 'reupload',
  EDIT_IMG: 'Edit Photo',
  UPLOAD_VIDEO_TIP:
    'Videos with brand logos or watermarks are likely to hit the review logic of Douyin, leading to a high probability of reducing the recommended weight of shared videos, delisting shared videos, and banning shared accounts. It is recommended that third-party applications handle non compliant watermarks in shared content on their own.',
  UPLOAD_PIC_TEXT: 'Publish images and text',
  PLEASE_UPLOAD_PIC_TEXT: 'Please upload and publish images and text',
  PUBLISH_COVER_IMG: 'Publish Cover',
  PLATFORM_CONFIG: 'Platform configuration',
  PUBLISH_TYPE_INSTANT_SEND: 'Send Now',
  PUBLISH_TYPE_SCHEDULE_SEND: 'Timed transmission',

  PUBISH_STATUS_DRAFT_TEXT: 'Draft',
  PUBLISH_STATUS_PENDING_TEXT: 'To be executed',
  PUBLISH_STATUS_PROCESSING_TEXT: 'In progress',
  PUBLISH_STATUS_PAUSED_TEXT: 'Paused',
  PUBLISH_STATUS_FINISHED_TEXT: 'Completed',

  PUBLISH_TASK_STATUS_PREPARATION_TEXT: 'To be released',
  PUBLISH_TASK_STATUS_PUBLISHING_TEXT: 'Posted in',
  PUBLISH_TASK_STATUS_SUCCESS_TEXT: 'Successfully published',
  PUBLISH_TASK_STATUS_FAILURE_TEXT: 'Publishing failed',
  PUBLISH_TASK_STATUS_PAUSED_TEXT: 'Paused',

  PERMISSION_TYPE_PUBLISH_TEXT: 'Open',
  PERMISSION_TYPE_PRIVATE_TEXT: 'Privacy',
  PERMISSION_TYPE_FRIENDLY_TEXT: 'Visible to friends',

  ALLOW: 'Allow',
  NOT_ALLOW: 'Not allow',
  NEW_PUBLISH_ITEM: 'New work release',
  VIEW_PUBLISH_ITEM: 'View work releases',
  EDIT_PUBLISH_ITEM: 'Editing and publishing of creations',

  PUBLISH_RECORDS: 'Release Record',
  TASK_STATUS: 'Task status',
  SUCCESS_COUNT: 'Number of successful publications',
  FAILURE_COUNT: 'Number of failed releases',
  ACTION_STOP_TASK: 'Pause task',
  ACTION_RESTART_TASK: 'Restart task',
  STOP_TASK_CONFIRM_TIP: 'Is there a moratorium on posting tasks?',
  STOP_TASK_CONTENT_TIP: 'The system will suspend content publishing after confirmation.',
  RESTART_TASK_CONFIRM_TIP: 'Does it restart the task?',
  RESTART_TASK_CONTENT_TIP: 'The system will restart the task release after confirmation.',
  CONFIRM_DELETE_TASK_CONFIRM_TIP: 'Does it delete the posting task?',
  CONFIRM_DELETE_TASK_CONTENT_TIP: 'Confirm that you will not be able to retrieve it after deletion.',
  ERROR_LINK_FORMAT_TIP: 'Wrong link format or incorrect parameters',
  CONFIRM_REPUBLISH_TIP: 'Is it being reissued?',
  CONFIRM_REPUBLISH_CONTENT_TIP: 'The system will perform content publishing immediately after confirmation',
  ACTION_BATCH_PUBLISH: 'Batch Release',
  PUBLISH_STATUS: 'Release status',
  PUBLISH_FINISH_TIME: 'Release completion time',
  PUBOISH_TITLE: 'Post video title',
  UN_OPENED: 'unopened',
  GENERAL_CONFIG: 'Common Configurations',
  UPLOAD_VIDEO: 'Upload Video',

  //=============直播==============
  VIDEO_INFO: 'Video information ',
  LP_SCREEN_GUIDE_ITEM_COUNT: 'Exposure frequency of live streaming entrance',
  LP_SCREEN_GUIDE_ITEM_WATCH_COUNT: 'Number of times to attract live streaming room traffic',
  LP_SCREEN_GUIDE_ITEM_ENTER_RATIO: 'Live streaming entrance click through rate',
  LIVE_STREAMING_TRAFFIC: 'live streaming',
  LIVE_STREAMING_RECOMANDATION: 'Live Streaming Recommendations',
  HEAT_FLOW: 'Heating flow',
  AD_FLOW: 'advertising flow',
  MALE: 'Male',
  FEMALE: 'Female',
  LIVE_DATA: 'Live streaming data',
  LIVE_ACCOUNT: 'Account',
  LIVE_END_TIME: 'End time',
  LIVE_DURATION: 'Live duration',
  ACTION_REPORT: 'Report',
  ACTION_REPLAY: 'Playback',
  STATIST_UNIT_GE: ' ',
  STATIST_UNIT_CI: ' ',
  STATIST_UNIT_CHANG: ' ',
  STATIST_UNIT_YUAN: ' ',
  STATIST_UNIT_REN: ' ',
  STATIST_UNIT_REN_CI: ' headcounts',
  STATIST_UNIT_SEC: ' sec(s)',
  STATIST_UNIT_MIN: ' min(s)',
  STATIST_UNIT_HOUR: 'hour(s)',
  STATIST_UNIT_DAY: ' day(s)',
  STATIST_UNIT_WEEK: ' week(s)',
  STATIST_UNIT_MONTH: ' month(s)',
  STATIST_UNIT_YEAR: ' year(s)',
  LIVIE_CHANNEL_TRAFFIC_ANALYSIS: 'Channel Traffic Analysis',
  LIVE_STATIST_ACCOUNT_NUM: 'Account num',
  LIVE_STATIST_ACCOUNT_NUM_TIP: 'Summarize the number of active accounts under the filtering conditions',
  LIVE_STATIST_LIVE_NUM: 'Live total',
  LIVE_STATIST_LIVE_TOTAL_NUM: 'Accumulated live streaming sessions',
  LIVE_STATIST_LIVE_NUM_TIP: 'Accumulated number of live streaming sessions initiated by accounts under summary filtering conditions',
  LIVE_STATIST_LIVE_DURATION: 'Accumulated live broadcast duration',
  LIVE_STATIST_LIVE_DURATION_TIP: 'Summarize the cumulative duration of live streaming initiated by accounts under filtering conditions',
  LIVE_STATIST_VIEW_NUM: 'Screen live watch UV',
  LIVE_STATIST_VIEW_NUM_TIP: 'Summarize the cumulative number of viewers initiated by the account for live streaming under filtering conditions, and do not reduce the number of viewers between different sessions',
  LIVE_STATIST_DURATION_AVG_TIP: 'Under the filtering conditions, the account initiates a live broadcast, and the viewing time in the live broadcast room (cumulative)/the number of users entering the live broadcast room (cumulative)',
  LIVE_STATIST_COMMENT_NUM: 'Cumulative Comments',
  LIVE_STATIST_COMMENT_NUM_TIP: 'Summarize the cumulative number of comments initiated by the account for live streaming under the filtering conditions',
  LIVE_STATIST_CLUE_NUM: 'Accumulated leads',
  LIVE_STATIST_CLUE_NUM_TIP: 'Summarize the cumulative number of leadsinitiated by accounts for live streaming under filtering conditions',
  INTERACTIVE_EFFECT: 'Interactive effects',
  DATA_INDICATORS: 'Data indicators',
  LIVE_INDICATOR_WATCH_UV: 'Screen live watch UV',
  LIVE_INDICATOR_UV: 'Screen live watch UV',
  LIVE_INDICATOR_UV_TIP: 'The number of viewers for the entire live broadcast',
  LIVE_INDICATOR_MAX_WATCH_UV: 'Screen live max watch UV',
  LIVE_INDICATOR_MAX_WATCH_UV_TIP: 'The highest number of online viewers for the entire live broadcast',
  LIVE_INDICATOR_AVG_WATCH_DURATION_TIP: 'Total viewing time for everyone/viewing time',
  LIVE_INDICATOR_FOLLOW_UV: 'Screen live follow UV',
  LIVE_INDICATOR_FOLLOW_UV_TIP: 'The number of followers obtained during the live broadcast, click on the number of followers in the upper left corner',
  LIVE_INDICATOR_COMMENT_UV: 'Screen live comment UV',
  LIVE_INDICATOR_COMMENT_UV_TIP: 'The number of people with commenting actions, de counting, counting as one person commenting multiple times',
  LIVE_INDICATOR_MSG_CONVERSATION_COUNT: 'Screen message conversation count',
  LIVE_INDICATOR_MSG_CONVERSATION_COUNT_TIP:
    'The number of people who generated private message conversations during this live broadcast (including cases where users did not speak and merchants set up proactive greetings)',
  LIVE_INDICATOR_CLUE_UV: 'Screen leads UV',
  LIVE_INDICATOR_CLUE_UV_TIP: 'Leads generated by various channels (small windmill card submission, private message retention, smart phone retention) during a single live broadcast time',
  LIVE_INDICATOR_WATCH_GT_MIN_COUNT: '>1 minute views',
  LIVE_INDICATOR_WATCH_GT_MIN_COUNT_TIP: 'Number of viewers with a single viewing time greater than 1 minute (non person count)',
  LIVE_INDICATOR_USER_REALTIME: 'Screen live user realtime',
  LIVE_INDICATOR_USER_REALTIME_TIP: 'Real time number of people per minute during the live broadcast. After downloading, the value is 0',
  LIVE_INDICATOR_FANS_WATCH_UV: 'Number of fans',
  LIVE_INDICATOR_BUSINESS_CARD_CLICK_COUNT: 'Screen live leads business card click count',
  LIVE_INDICATOR_BUSINESS_CARD_CLICK_COUNT_TIP:
    'Only the number of clicks (not the number of people) of the cards (the explanation card at the lower right corner of the Douyin APP live broadcast room and the card in the windmill) is counted',
  LIVE_INDICATOR_SHOW_COUNT: 'Exposures',
  LIVE_INDICATOR_SHOW_COUNT_TIP: 'The number of times the exposure reached in the live broadcast room will not be counted again',
  LIVE_INDICATOR_ENTER_RATIO: 'Screen live enter ratio',
  LIVE_INDICATOR_ENTER_RATIO_TIP: 'Accumulated viewership/exposure',
  LIVE_INDICATOR_ICON_CLICK_COUNT: 'Screen live icon hits',
  LIVE_INDICATOR_ICON_CLICK_COUNT_TIP: 'Only the clicks on the small windmill were counted (excluding the explanation card), and it is the number of times (not the number of people)',
  LIVE_INDICATOR_ICON_CLICK_RATE: 'Screen live icon click rate',
  LIVE_INDICATOR_ICON_CLICK_RATE_TIP: 'Click through rate of small windmills=number of clicks on small windmills/number of views',
  LIVE_INDICATOR_INTERACTION_RATIO: 'Screen live interaction ratio',
  LIVE_INDICATOR_INTERACTION_RATIO_TIP: 'Interactions/cumulative views',
  LIVE_INDICATOR_BIZ_WECHAT_COST: 'Screen ad biz wechat cost',
  LIVE_INDICATOR_BIZ_WECHAT_COST_TIP: 'Adding WeChat Cost=Advertising Consumption/Number of Enterprise WeChat Additions',
  LIVE_INDICATOR_FORM_COUNT: 'Screen add form count',
  LIVE_INDICATOR_FORM_COUNT_TIP: 'The same name indicator for live streaming on a massive advertising platform is only used when mounting small snowflakes or external links.',
  LIVE_INDICATOR_WATCH_COUNT: 'Screen live watch count',
  LIVE_INDICATOR_WATCH_COUNT_TIP: 'The number of views for the entire live broadcast will not be recalculated',
  LIVE_INDICATOR_FANS_WATCH_RATIO: 'Screen live fans watch ratio',
  LIVE_INDICATOR_FANS_WATCH_RATIO_TIP: 'Number of fans watching/cumulative number of viewers',
  LIVE_INDICATOR_AVG_ONLINE_UV_BY_ROOM: 'Screen live average online UV by room',
  LIVE_INDICATOR_AVG_ONLINE_UV_BY_ROOM_TIP: 'Sum of maximum online users per minute/minutes of live streaming',
  LIVE_INDICATOR_UV_WITH_PREVIEW: 'Screen live UV with preview',
  LIVE_INDICATOR_UV_WITH_PREVIEW_TIP:
    'The number of viewers corresponding to the download page of Douyin APP is equal to the number of people entering the live broadcast room plus the number of people who have watched the live broadcast room for more than 3 seconds but have not entered the live broadcast room',
  LIVE_INDICATOR_SHARE_COUNT: 'Screen live shares',
  LIVE_INDICATOR_SHARE_COUNT_TIP: 'The number of times there is sharing behavior, do not recalculate',
  LIVE_INDICATOR_SHARE_UV: 'Screen live share UV',
  LIVE_INDICATOR_SHARE_UV_TIP: 'The number of people who engage in sharing behavior should be de counted, and each person sharing multiple times should be counted as one',
  LIVE_INDICATOR_SHARE_RATIO: 'Screen live share ratio',
  LIVE_INDICATOR_SHARE_RATIO_TIP: 'Number of shares/cumulative views',
  LIVE_INDICATOR_LIKE_UV: 'Screen live like UV',
  LIVE_INDICATOR_LIKE_UV_TIP: 'The number of people who have liked behavior should be removed from the statistics, and each person who likes multiple times should be counted as one',
  LIVE_INDICATOR_LIKE_COUNT: 'Screen live likes',
  LIVE_INDICATOR_LIKE_COUNT_TIP: 'The number of likes, do not recalculate',
  LIVE_INDICATOR_LIKE_RATIO: 'Screen live like ratio',
  LIVE_INDICATOR_LIKE_RATIO_TIP: 'Number of likes/cumulative views',
  LIVE_INDICATOR_COMMENT_COUNT: 'Comments',
  LIVE_INDICATOR_COMMENT_COUNT_TIP: 'Number of times there was a comment action, not to be recounted',
  LIVE_INDICATOR_COMMENT_RATIO: 'comment rate',
  LIVE_INDICATOR_COMMENT_RATIO_TIP: 'Comments / Cumulative Views',
  LIVE_INDICATOR_INTERACTION_COUNT: 'Number of interactions',
  LIVE_INDICATOR_INTERACTION_COUNT_TIP: 'There are like, comment, share any number of actions, do not re-count them.',
  LIVE_INDICATOR_INTERACTION_UV_COUNT: 'Number of interactions',
  LIVE_INDICATOR_INTERACTION_UV_COUNT_TIP:
    'The number of people who have liked, commented, or shared any action is de-emphasized, and one person who has liked, commented, or shared multiple times is counted as one time.',
  LIVE_INDICATOR_FANS_CLUB_JOIN_UV: 'Add the number of fan clubs',
  LIVE_INDICATOR_FANS_CLUB_JOIN_UV_TIP: 'Adding fanbase numbers, de-duplicating statistics',
  LIVE_INDICATOR_FANS_CLUB_JOIN_UV_RATIO: 'Rate of increase in the number of tours',
  LIVE_INDICATOR_FANS_CLUB_JOIN_UV_RATIO_TIP: 'Number of fans added / Cumulative number of viewers',
  LIVE_INDICATOR_FOLLOW_RATIO: 'attention rate',
  LIVE_INDICATOR_FOLLOW_RATIO_TIP: 'Number of upvotes/cumulative number of viewers',
  LIVE_INDICATOR_CLUE_CONVERT_RATIO: 'Leads Conversion Rate',
  LIVE_INDICATOR_CLUE_CONVERT_RATIO_TIP: 'Number of full-scene leads/cumulative viewers',
  LIVE_INDICATOR_FORM_COST: 'Forms Costs',
  LIVE_INDICATOR_FORM_COST_TIP: 'Form Cost = Ad Consumption / Number of Form Submissions',
  LIVE_INDICATOR_LONGTERM_MSG_CLUE_UV: 'Private Messages for Long-Term Conversion',
  LIVE_INDICATOR_LONGTERM_MSG_CLUE_UV_TIP:
    'Private messaging sessions occurred during the live broadcast but no funds were retained, and the number of people who retained funds within 1 hour of going off the air. data will not be available until after May 10, 2023',
  LIVE_INDICATOR_BUSINESS_CARD_CLICK_RATE: 'Card Hits',
  LIVE_INDICATOR_BUSINESS_CARD_CLICK_RATE_TIP: 'Card Hits = Card Hits / Card Exposures',
  LIVE_INDICATOR_BIZ_WECHAT_ADD_COUNT: 'Enterprise wechat add number',
  LIVE_INDICATOR_BIZ_WECHAT_ADD_COUNT_TIP: 'Huge ad platform live big screen indicator of the same name, limited to use when mounting small snowflakes or external links',
  LIVE_INDICATOR_CLUE_COST: 'Leads cost',
  LIVE_INDICATOR_CLUE_COST_TIP: 'Amount of ads consumed in the live room/total number of leads in the live room',
  LIVE_INDICATOR_STAT_COST: 'Advertising consumption',
  LIVE_INDICATOR_STAT_COST_TIP: 'The advertising consumption associated with this live broadcast room',
  LIVE_COMMENT_NUMBER: 'Total danmu: {count}',
  LIVE_INDICATOR_TIP: 'Trend analysis should display at least one dimension and at most three dimensions of data',
  LIVE_STATIST_AUDIENCE_TRAFFIC_SOURCE: 'Audience traffic sources',
  LIVE_STATIST_AGE: '{age} years old',
  LIVE_STATIST_AGE_ABOUT: '{age}+',
  LIVE_STATIST_AUDIENCE_DATA: 'Audience data',
  LIVE_STATIST_INTERACTIVE_DATA: 'Interactive data',
  LIVE_STATIST_CONVERT_DATA: 'Convert data',
  SATREAMING_SHORT_VIDEO: 'Streaming short videos',
  TRAFFIC_CONVERSION: 'Traffic conversion',
  USER_PROFILE_ANALYSIS: 'User Profile Analysis',
  RESIDENCE_DISTRIBUTION: 'Residence distribution',
  TRAFFIC_ANALYSIS: 'Traffic analysis',
  CITY_TOP_10: 'Top 10 Cities',
  PROVINCE_TOP_10: 'Top 10 Provinces',
  LIVE_DETAIL_PLAY_TIP: 'Only pop-ups that are not self-explanatory or do not relate to the official rules will be displayed in each room.',
  LIVE_DETAIL_TAB_COMMENT: 'Comment',
  LIVE_DETAIL_TAB_HIGH_LIGHT: 'Highlighting moment',
  LIVE_INDICATOR_GROUP_POPULARITY: 'Popularity index',
  LIVE_INDICATOR_GROUP_INTERACTIVE: 'Interaction indicators',
  LIVE_INDICATOR_GROUP_CONVERT: 'Conversion indicators',
  LIVE_INDICATOR_GROUP_OTHER: 'Other indicators',
  REAL_TIME_DYNAMICS: 'Real time dynamic',
  TIME_MINUTE_TPL: '{time} minute(s)',
  TIME_HOUR_TPL: '{time} hour(s)',
  LIVE_CLICK_PLAY_HIGH_LIGHT: 'Click to play highlight moment',
  LIVE_DETAIL: 'Live streaming details',
  SUMMARY_OF_BARRAGES: 'Danmu',
  LIVE_VIEW_USER_COUNT: 'Cumulative number of viewers',
  CLUE_ANALYSIS: 'Leads analysis',
  CLUE_TRAFFIC_SOURCCE: 'Leads traffic sources',
  AREA_DISTRIBUTION: 'Geographical distribution',
  CITY: 'City',
  SEGMENTED_SCENE_ANALYSIS: 'Segmented scene analysis',
  LIVE_ACCUMULATED_VIEWER_TOTAL: 'Accumulated number of viewers',
  PEAK_ONLINE_USER: 'Peak number of online users',
  CLUE_COLLECTION_NUM: 'Leads collection volume',
  GIFT_RECEIVED_NUM: 'Number of gifts received',
  GIFT_COLLECTION_FLOW_NUM: 'Receiving gift flow',
  CLUE_VOMUME_MOUNTING_RATE: 'Leads volume mounting rate',
  ADD_FOLLOW: 'Add Follow',
  TOTAL_HEAT: 'Total heat',
  FAN_DISTRIBUTION: 'Fan distribution',
  CHANNEL_DISTRIBUTION: 'Channel distribution',
  REGION_AREA_TOP_5: 'Top5 Regional Distribution',
  SHARE_LIVE_ROOM_USER_COUNT: 'Share the number of people in the live broadcast room',
  SHARE_LIVE_ROOM_TIME_COUNT: 'Number of times sharing live broadcast room',
  GIFT_GIVE_USER_COUNT: 'Number of gift givers',
  GIFT_GIVE_HEAT: 'Gift giving enthusiasm',
  ENTER_ROOM_USER_COUNT: 'Number of people entering the room',
  LEAVE_ROOM_USER_COUNT: 'Number of departures',
  DATA_OVERVIEW: 'Data screening ',
  TRAFFIC_AND_SOURCE_TREND: 'Traffic&Source Trends',
  TRAFFIC_TREND: 'Traffic trend',
  INTERACTIVE_TREND: 'Interactive trends',
  CUMULATIVE_WATCH_COUNT: 'Accumulated viewership statistics',
  ADD_TRACKING_STATISTICS: 'New Follow Statistics',
  LIVE_COVER_TITLE: 'Live Title',
  WX_ACCOUNT: 'public number',
  APPLET: 'applet',
  BROADCAST_NOTICE: 'Notification of commencement of broadcast',

  //================工作台============
  PLAY_ANALYSIS: 'Play Analysis',
  CONVERT_ANALYSIS: 'Conversion analysis',
  LIVE_PLAY_TOP10: 'Top 10 live streaming rankings',
  VIDEO_PLAY_TOP10: 'Video Ranking Top 10',
  FANS_SORT_TOP10: 'Top 10 fan rankings',
  LEADS_RORT_TOP10: 'Top 10 leads ranking',
  PLAY_COUNT: 'Playback count',
  TIME: 'Time',
  ASC: 'Ascending order',
  DESC: 'Descending order',
  BY_NAME: 'Press {name}',
  CONTENT_ANALYSIS: 'Content analysis',
  LIVE_OVERVIEW: 'Live streaming overview',
  LIVE_TREND_ANAYLISIS: 'Live streaming trend analysis',
  LIVE_TREND: 'Live streaming trends',
  FANS_ANALYSIS: 'Fan analysis',
  RANK_STATISTICS: 'Ranking statistics',
  VIDEO_OVERVIEW: 'Video Overview',
  VIDEO_TREND_ANAYLISIS: 'Video Trend Analysis',
  TRANSFORMATION_ANALYSIS: 'Conversion analysis',
  VIDEO_CONVERSION: 'Video conversion',
  LIVE_CONVERSION: 'Live streaming conversion',
  PEOPLE_NUM: '{num} people',
  PEOPLE: ' ',
  NUM_CLOCK: '{num}:00',
  NUM_AGE: '{num} years old',
  OVER_NUM_AGE: '{num}+',
  FANS_COUNT: 'Fans',
  UNKNOWN: 'Unknown',

  LP_SCREEN_CLUE_UV: 'Screen leads UV',
  LP_SCREEN_LIVE_CARD_CLICK_UV: 'Number of clicks on leads tools',
  LP_SCREEN_LIVE_SHOW_UV: 'Impression count in the live streaming room',
  LP_SCREEN_LIVE_WATCH_UV: 'Number of people entering live streaming',

  LIVE_TOTAL: 'Live total',
  LP_SCREEN_LIVE_COMMENT_COUNT: 'Comments',
  LP_SCREEN_LIVE_LIKE_COUNT: 'Screen live likes',
  LP_SCREEN_LIVE_SHARE_COUNT: 'Screen live shares',
  LP_SCREEN_LIVE_VIEW_UV: 'Screen live watch UV',

  INTERACTION_TOTAL: 'Total interactions',
  ITEM_CLUE_TOTAL: 'Leads count from video source',
  PLATY_TOTAL: 'Cumulative plays',

  COMMENT_TOTAL: 'Comments',
  VIDEO_ITEM_TOTAL: 'Videos',
  LIKE_TOTAL: 'Likes',
  PLAY_TOTAL: 'Plays',
  SHARE_TOTAL: 'Shares',
  OPERATION_ACCOUNT_TOTAL: 'Accounts',
  EXCEPTION_ACCOUNT: 'Abnormal Accounts',
  FANS_TOTAL: 'Fans',
  CLUE_TOTAL: 'Leads',
  LIVT_TOTAL_TIME: 'Total time of live streaming',
  TIMES_EVERY_WEEK: 'Sessions/Week',
  LIVE_ACCUMULATED_WATCH_TOTAL: 'Accumulated number of viewers in live streaming',
  AVG_WATCH_TIME: 'Screen live average watch duration',
  AVG_WATCH_TIME_SECOND: 'Per capita viewing time (seconds)',

  LIVE_FREQUENCY: 'Frequency of live broadcasts',
  VIDEO_PUBLISH_TOTAL: 'Video releases',
  PALY_TOTAL: 'Accumulated number of viewers',
  PUBLISH_FREQUENCY: 'Release frequency',
  LIVE_WATCH_COUNT: 'Screen live watch count',
  LIVE_LIKE_COUNT: 'Screen live likes',
  LIVE_COMMENT_COUNT: 'Comments',
  LIVE_COMMENT_UV: 'Screen live comment UV',
  LIVE_SHARE_COUNT: 'Screen live shares',
  THEATER_COMMAND: 'Theater Command',
  STATIST_UNIT_JIA: 'home',
  VIDEO_TOTAL: 'Total video volume',
  STAY_ANALYSIS: 'Stay analysis',
  INTERACTIVE_ANALYSIS: 'Interactive analysis',
  LIKE_CLICK: 'Like',
  RISING_FOLLOWERS_ANALYSIS: 'Analysis of powder increase',
  NEW_FUNS_COUNT: 'New fan base',
  RETENTION_ANALYSIS: 'Retention analysis',
  RETNETION_AMOUNT: 'Retained capital',
  WORK_SUMMARY_ANALYSIS: 'Creation analysis',
  LIVE_SUMMARY_ANALYSIS: 'Live streaming analysis',

  //=============系统授权===============
  ACCOUNT_STATUS_INIT: 'Initializing',
  ACCOUNT_STATUS_EXPIRED: 'The QR code has expired',
  ACCOUNT_STATUS_CONFIRMED: 'Scanned code confirmation',
  ACCOUNT_STATUS_REFUSED: 'Authorized login has been denied',
  ACCOUNT_STATUS_NEW: 'Waiting for scanning code',
  ACCOUNT_STATUS_ERROR: 'An error occurred',
  ACCOUNT_STATUS_SUCCESS: 'Authorization successful',
  ACCOUNT_STATUS_FAILURE: 'Failed authorisation',
  ACCOUNT_STATUS_EXCEPTION: 'Authorization exception',
  ACCOUNT_STATUS_SCANNED: 'Scanned code',
  AUTHORIZATION_SUCCESS: 'Authorization successful',
  DOUYIN_NO_COPY_ITP: 'In the Jitterbug app, go to the "Me" page and click on Jitterbug to copy it.',
  SCAN_QR_ERROR: 'Scan code exception',
  AUTHORIZATION_STATUS: 'Authorization status',
  LGOIN_AUTH_STATUS: 'Login status',
  GHOST_AUTH_STATUS: 'possessed',
  GHOST_AUTH_STARTTIME: 'Possession authorization start time',
  GHOST_AUTH_ENDTIME: 'End time of possession authorization',
  ACCOUNT_BIND_STATUS: 'Binding Status',
  ACCOUNT_OCEAN_BIND_STATUS: 'Huge Engine License',
  CREATOR_AUTH_STATUS: 'Creator Authorization Status',
  CREATOR_AUTH_STARTTIME: 'Creator authorization start time',
  CREATOR_AUTH_ENDTIME: 'Creator Authorization End Time',
  ACCOUNT_TYPE: 'Account type',
  DOWNLOAD_AUTH_LINK: 'View authorization link',
  DOWNLOAD_AUTH_LINK_TIP: 'The Douyin platform authorization is valid for 195 days, and the login authorization is valid for 60 days. Re-authorization is required after expiration.',
  AUTHORIZATION_PLATFORM: 'Authorization platform',
  PLATFORM_AUTHORIZATION: 'Platform authorization',
  DELER_CODE: 'Dealer code',
  SYNC_BI_DATA: 'BI report updates',
  SYNC_CLUE_RULES: 'Leads Rule Update',
  ACCOUNT_AUTHORIZATION: 'Account authorization',
  AUTHORIZATION_EXPIRED: 'Authorization has expired',
  ADD_ACCOUNT: 'Add Account',
  UN_AUTHORED_ACCOUNT: 'Unauthorized account {num}',
  ANONMOUS_ACCOUNT_AUTHORIZATION_TIP: 'Currently running "{businessUnionName}" account authorization',
  NEW_AUTHORIZATION_ACCOUNT: 'New Authorized Account',
  ACCOUNT_AUTHORIZED_TIP:
    'After authorization, video data will pull video-related data from the last 30 days; sessions will pull relevant data from the last year; and live data will pull live data from the last 60 days.',
  UNAUTHORIZATION_TIP: 'Please authorize the platform first',
  UNLOGIIN_AUTHORIZATION: 'Please login for authorization first',
  AUTHORIZATION_ORG_UNSELECTED_TIP: 'If no organization is selected the default is the branded Shakeology number',
  PHONENUMBER_PLACEHOLDER: 'Please enter the correct cell phone number',
  POI_POSITION: 'Geographic location',
  REMARK: 'Remark',
  OPERATOR_PHONE_NUM: 'Cell phone',
  OPEN_QR_CODE_POP_UP_TIP: 'Click to open the QR code pop-up window for',
  AUTHORIZATION: 'Authorizations',
  REAUTHORIZATION: 'Reauthorization',
  LOGIN_AUTHORIZATION: 'Login Authorization',
  CREATOR_AUTHORIZATION: 'Creator License',
  PLEASE_AGREE_AUTHORIZE: 'Please agree to authorization first',
  PLEASE_PLATFORM_AUTHORIZE: 'Please authorize the platform first',
  CONFIRM_REAUTHORIZATION: 'Confirm reauthorization?',
  AFTER_REAUTHORIZATION_TIP: 'Re-authorization will clear the original authorization status.',
  AUTHORIZATION_TIME: 'Authorization time',
  CHECK_INDICATE_AGREEMENT: 'By checking this box, you agree to',
  APPLICAITON_LICENSE_AGREEMENT: 'Application License Agreement',
  ACCOUNT_AUTHORIZATION_CANCELED_TIP: 'The authorization of this account has been canceled by the administrator in the backend of Jitterbug, please click to re-authorize it',
  ACCOUNT_AUTHIRIZATION_EXPIRED_TIP: 'The authorization for this account has expired, please click Re-authorization',
  CLICK_HERE: 'Click here to',
  GOTO_PLATFORM_AUTHORIZATION_PAGE_TIP: 'Click to jump to the Jitterbug account authorization page for the',
  OBTAINED_FOLLOWING_PERMISSION: 'The following permissions have been granted',
  WILL_OBTAINE_FOLLOWING_PERMISSION: 'The following permissions will be granted',
  QUICK_ANS_SECURE_LOGIN: 'Quick and Secure Login',
  RETRACT_UNOBTAINED_FOLLOWING_PERMISSION: 'Put away unauthorized access',
  EXPEND_UNOBTAINED_FOLLOWING_PERMISSION: 'Expand Unavailable Privileges',
  REQUIED_ITEM_CHECKED_TIP: 'Please check that the required fields and related items are entered correctly!',
  PHONE_NUMBER: 'Mobile telephone number',
  DELER_CODE_REQUIRED_TIP: 'Please enter dealer code',
  ENTERPRISE_ACCOUNT: 'Enterprise number',
  PERSONAL_ACCOUNT: 'Personal number',
  CLOSE: 'Cloture',
  TRY_REFRESH_QRCODE_TIP: 'Error refreshing QR code, please try again.',
  CLICK_TO_REAUTHORIZE: 'Click Reinitiate Authorization',
  CLICK_LOAD_QRCODE: 'Click to load QR code',
  CLICK_TO_RELOAD: 'Click to reload',
  COPY_AUTHORIZATION_INFO: 'Copying authorization information',
  COPY_TEXT_TO_CLIPBOARD: 'The text has been copied to the clipboard!',
  COPY_TEXT_FAILURE: 'Failed to copy text',
  AUTH_LINK: 'Authorized Links',
  LOAD_MROE: 'Load More',
  SELECT_POSITION: 'Please select a location',
  CLEAR: 'Removals',
  SELECT_AFTER_INPUT_KEYWORD: 'Please enter the keywords before selecting',
  PLEASE_SET_POSIITON: 'Please set the position',
  POSITION: 'Placement',
  REGION: 'Shore',
  PLATFROM_QR_AUTH_TIP1: 'Get your cell phone number',
  PLATFROM_QR_AUTH_TIP2: 'Get your {platform} avatar, nickname',
  PLATFROM_QR_AUTH_TIP3: 'Post public works to your {platform} account (authorization does not require you to post them).',
  PLATFROM_QR_AUTH_TIP4: 'Get the data (number of plays, likes, comments, shares) of the {platform} videos you have published',
  AUTHORIZATION_INFO: 'Authorization Information',
  ORG_CODE: 'Organization code',
  VIEW_AUTHORIZED_ACCOUNT: 'View Authorized Accounts',
  LOGIN_AUTH_START_TIME: 'Login authorization start time',
  LOGIN_AUTH_END_TIME: 'Login authorization end time',
  PLATFORM_AUTH_END_TIME: 'Platform authorization start time',
  OCEAN_ENGINE_AUTH: 'huge amount of authority',
  OPEN_APP_AND_SCAN_QR: 'Use {platform}APP and open [Home - More - Swipe].',

  //============系统语义分类========
  KEYWORD_CHANGED_TIP: 'Each new, modified, or restarted semantic classification will officially take effect at 0:00 the next day',
  NO_KEYWORD_CLASSFICATION: 'There is currently no keyword classification available',
  KEYWORD_TOTAL_TIP: 'A total of {count} keyword classifications',
  CONVERSATION: 'Conversation',
  COMMENT: 'Comment',
  BARRAGE: 'Danmu',
  CLASSIFICATION_NAME: 'Classification name',
  SEMANTIC_CLASSIFICATION_TYPE: 'Affiliated semantic classifications',
  KEYWORD: 'Keyword',
  INCLUDE_KEYWORDS: 'Related Keywords',
  EXCLUDED_KEYWORDS: 'Masked Keywords',
  CONFIRM_DELETE_KEYWORD_TIP: 'Are you sure to delete the category "{name}" and keywords?',
  CONFIRM_DELETE_KEYWORD_SUB_TIP: 'After deletion, the content will not be semantically categorized even if it contains blocked keywords, and no summarization statistics will be done',
  CONFIRM_ENABLE_KEYWORD_TIP: 'Confirm enabling category {name} and keywords?',
  CONFIRM_ENABLE_KEYWORD_SUB_TIP: 'Once enabled, the semantic categorization will go into effect at 0:00 tomorrow',
  CONFIRM_DISABLE_KEYWORD_TIP: 'Confirm disabling category {name} and keywords?',
  CONFIRM_DISABLE_KEYWORD_SUB_TIP: 'When disabled, content is not semantically categorized even if it contains blocked keywords, and no summarization statistics are done',
  CONFIG_KEYWORD_RULE_TIP: 'Configure at least one keyword or dynamic rule in the Associated Keywords',
  CONFIG_KEYWORD_RULE_REPEATION_TIP: 'Duplicate keyword "{keyword}" exists between associated keywords or masked keywords',
  PLEASE_ENTER_CATEGROY_NAME: 'Please enter a category name',
  CATEGROY_NAME_VERIFICATION_TIP: 'Classification name can only contain Chinese and English numbers',
  CATEGROY_EXISTED: 'Category already exists',
  AFFECT: 'Effect',
  RULE_CONFIG: 'Rule Configuration',
  INCLUDE_KEYWORDS_CONFIG_SUB_TIP: 'Content containing associated keywords is labeled with semantic categories',
  EXCLUDE_KEYWORDS_CONFIG_SUB_TIP: 'Semantic categorization is not posted if the content contains masked keywords',
  KEYWROD_VERIFICATION_RULE_TIP:
    'Multiple keywords separated by carriage return key line feed, up to 100 keywords, a single keyword up to 50 characters, only support for Chinese and English, numbers, case insensitive',

  KEYWROD_VERIFICATION_RULES_LENGH_TIP: 'Add up to 100 rules',
  SINGLE_KEYWROD_RULE_TEXT_LENGH_TIP: 'The length of a single rule can be up to 50 characters.',
  KEYWROD_RULE_TEXT_FORMATTER_TIP: 'Only support Chinese, English, numbers, not case sensitive',

  KEYWROD_VERIFICATION_RULE_PLACEHOLDER: ' One line, support Chinese and English, numbers and symbols',
  DYNAMIC_RULE: 'Dynamic rule',
  CONFIG_RULE_REQUIRED_TIP: 'Configure at least one rule',
  CONFIG_RULE_ITEM_REQUIRED: 'Required fields for rules',
  SYSTEM_RULE: 'System rules',
  CUSTOM_RULE: 'Customized rules',
  CUSTOM_RULE_NAME: 'Customized Rule Names',
  PLEASE_SELECTED_RULE: 'Please select rules',
  PLEASE_ENTER_RULE_NAME: 'Please enter a custom rule name',
  PLEASE_ENTER_REGULAR_EXPRESSION: 'Please enter a regular expression',
  REGULAR_EXPRESSION_VERIFICATION: 'regular expression validation',
  REGULAR_EXPRESSION: 'Regular expression (math.)',
  PLEASE_ENTER_RIGHT_REGULAR_EXPRESSION: 'Please enter the correct regular expression',
  CLASSIFICATION_AND_DATA_FILLED_TIP: 'Both the category name and the sample data need to be filled in',
  SAMPLE_DATA: 'Sample data',
  PLEASE_ENTER_SAMPEL_DATA: 'Please enter sample data',
  VERIFICATION_RESULT: 'Verification results',
  CLICK_TO_VIEIFY: 'Click to verify',
  CLICK_TO_REVALIDATION: 'Click to re-verify',
  PASS: 'Pass',
  NOT_PASS: 'Not pass',
  RULE_TYPE: 'Type of rule',
  RULE_NAME: 'Name of the rule',
  CREATE_KEYWORD: 'New semantic categories',
  VEIW_KEYWORD: 'View semantic categories',
  EDIT_KEYWORD: 'Editorial semantic categorization',

  //==============权限管理==============
  RESOURCE: 'Resources',
  ATTRIBUTE_SCOPE: 'Attribute Range',
  OPERATE_TYPE: 'Type of operation',
  ORG_STRUCTURE: 'Organizational structure',
  ACTION_ADD_USER: 'Add user',
  ACTION_BATCH_REMOVE: 'Batch removal',
  RANDOM: 'Random',
  SELECT_NAME: 'Select {name}',
  ROLE: 'Roles',
  USER_GROUP: 'User group',
  ROLE_NAME: 'Character name',
  FUNCTION_PERMISSION: 'Functional privilege',
  SUPPORT_CN_EN_NUMBER_UNLINE_TEXT_TIP: 'Supports English, Chinese, numbers and underscores.',
  SUPPORT_EN_NUMBER_UNLINE_TEXT_TIP: 'Supports English, Chinese, numbers and underscores.',
  COUNT_CN_EN_NUMBER_UNLINE_TEXT_REQUIRD_TIP: 'Maximum {count} characters, supports Chinese, English, numbers and underscores.',
  COUNT_CN_EN_NUMBER_UNLINE_SYMBOL_TEXT_REQUIRD_TIP: 'Maximum {count} characters, supports Chinese and English, numbers and symbols.',
  NAME_EXISTED: '{name} already exists',
  FUNCTION_PERMISSION_SET_TIP: 'Please set the data range corresponding to the operation privilege in the user group.',
  PERMISSION_CONFIG: 'Privilege Configuration',
  USER_COUNT: 'Number of users',
  USERGROUP_NAME: 'User group name',
  BIND_USERGOUP: 'Associated user group',
  OPERATION_PERMISSION: 'Operating privilege',
  CONFIRM_DELETE_USER_CONTENT_TIP: 'Deleted users will not be retrieved and will not be able to log in to the system',
  CONFIRM_DELETE_ROLE_CONTENT_TIP: 'Deleted roles will not be recovered and user privileges will be removed.',
  CONFIRM_DELETE_USERGROUP_CONTENT_TIP: 'Deleting a user group removes the permissions of the associated users',

  USERNAME: 'User ID',
  EMAIL: 'Inbox',
  BIND_ROLE: 'Related Roles',
  DATA_PERMISSION: 'Data privileges',
  CURRENT_GROUP: 'User group',
  OVERVIEW_PERMISSION: 'List of data permissions',
  CONFIRM_DISABLE_USER: 'Confirm that the user is disabled?',
  CONFIRM_DISABLE_USER_CONTENT_TIP: 'Disabled users will not be able to log into the system',
  GROUP_USERS: 'User in a group',
  USER_CONFIG: 'Iser configuration',
  ACTION_SAVE_AND_CONFIG_PERMISSION: ' Save and configure permissions',
  CONFIG_PERMISSION: 'Config',
  CONFIG_USERGROUPS: 'Configuring User Groups',
  PLEASE_CONFIG_NAME: 'Please configure {name}',
  CONFIG_PERMISSION_VALIDATE_TIP: 'Required fields are not filled in for data permissions',
  OPERATOR_SYMBOL: 'Matchmaker',
  EQUAL: 'Equal',
  NOT_EQUAL: 'Not equal ',
  MODULE: 'Module',
  RESOURCE_OBJ: 'Resource object',
  ROLE_CONFIG_ATTR_MISSING: 'The current data rights role object has been deleted, please reconfigure it.',
  NO_PERMISSION_VISIT: 'No entitlement access',
  PLEASE_CONTACT_ADMINISTRATOR_TO_OBTAIN_PERMISSION: 'Please contact the administrator for permission',
  NO_PROMESSION: 'No operating privileges for the time being',

  //=================TAG管理===============
  SEARCH_TAG: 'Support for tag name search',
  TAG_NAME: 'Tag name',
  TAG_TYPE: 'Tag Type',
  TAG_VALUE: 'Tagged value',
  DEL_TAG_CONFIRM_TIP: 'Are tags deleted, not retrievable after deletion, and the corresponding content tags will be removed?',
  TAG_OBJECT: 'Tag object',
  TAG_VALUE_TYPE: 'Single/multiple values',
  AUTO_RULE: 'Auto-rule',
  AUTO_RULE_CONFIG_TIP: 'The following conditions are met (multiple conditions have an "and" relationship).',
  CHECK_RULE_CONFIG_ERROR_TIP: 'Unconfigured options exist',
  CONDITION: 'Add conditions',
  OR: 'or',
  AND: 'and',
  TAG_SETTING_RULE: 'Tagged value rules',
  AUTO_RULE_CONFING_TIP: 'The following conditions will be automatically tagged',
  UNKNOWN_NAME: 'untitled',
  NAME_REQUIRED: '{name} is required',
  UPATE_TAG_CONFIRM_TIP: 'Is it confirmed to update the label content, after confirmation the system will automatically update the relevant content label?',
  AUTO: 'automation',
  MANUAL: 'manually operated',
  SINGLE_VALUE: 'single-valued',
  MULTIPLE_VALUE: 'multivalued',
  TAG_ANALYLSIS: 'Label Statistical Analysis',
  CONTENT: 'element',
  ANALYLSIS: 'statisticians',
  COVER_RATE: 'site coverage',
  COVER_COUNT: 'Amount of coverage',

  EXCELENT_CASE: 'Excellent Case',
  EXCELENT_VIDEO: 'Outstanding Video',
  EXCELENT_LIVE: 'Outstanding Live Streaming',
  CONFIRM_DELETE_EXCELENT_CASE_TIP: 'Canceling will cancel the favorites of this excellent case!',
  RECOMADATION_REASION: 'Recommended Reasons',
  JOIN_EXCELLENT_CASE: 'Join Excellent Cases',
  JOIN_EXCELLENT_CASE_ALREADY: 'The current material has been added to the excellent case',
  OPERATIONAL_MATERIAL: 'Operation Material',
  INDUSTRY_HOTSPOT: 'Industry Hotspots',
  AUTOMOTIVE_RANKING: 'Car List',
  HOTSPOT_EVENT: 'Hot spot',
  POPULAR_VIDEO: 'Explosive Video',
  HOT_TOPIC: 'Hot Topics',
  INDUSTRY_COLLECATION: 'Industry Collections',

  //热点
  CAR_OVERALL: 'Car List',
  COMMENT_CAR: 'Car assessment',
  PLAY_CAR: 'Playing',
  USE_CAR: 'Vehicle',
  DRIVER: 'Driving',
  TYPE: 'Type',
  COLLECT: 'Collect',
  COLLECTED: 'Collected',

  CONFIRM_DELETE_INDUSTIAL_COLLECT_TIP: 'Canceling will cancel the favorites of this excellent case!',
  TOPIC_NAME: 'Topic Name',
  HEART_TREND: 'Heat Trend',
  HEART_VALUE: 'temperature value',
  HIGHEST_HEART_VALUE: 'highest heat value',
  RELATED_VIDEOS: 'Number of related videos',
  VIDEO_NUMS: 'video volume',
  AVG_PLAY_NUMS: 'average number of scripts played',
  AVATAR: 'Avatar',
  LIST_MONTH_TOTAL_TIMES: 'Number of times on the list in the last month',
  INFLUENCE_INDEX: 'Impact index',
  FOLLOW_RATE: 'upward mobility (e.g. of fans)',
  CLICK_RATE: 'click through rate (CTR) (Internet)',
  LIKE_CNT: 'Add Likes',
  PALY_CNT: 'New plays',
  FOLLOW_CNT: 'fan base',
  CAR_MARKET: 'car buying and selling',
  CAR_USAGE: 'Automobile use',
  CAR_DISPLAY: 'Car Showcase',
  NOT_CURRENTLY_SUPPORTED: 'Not supported at this time',
  CLICK_VIEW_MORE: 'Click for details',
  CLICK_CANCEL_COLLECT: 'Click to unfavorite',
};
