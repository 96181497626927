import ProSkeleton from '@ant-design/pro-skeleton';

export default () => {
  return (
    <div
      style={{
        background: '#fafafa',
        padding: 24,
        maxHeight: 'calc(100vh - 100px)',
        overflow: 'hidden',
      }}
    >
      <ProSkeleton type="descriptions" />
    </div>
  );
};
